import { NgModule } from '@angular/core';
import { ScreenOverlayNoBarComponent } from './screen-overlay-nobar.component';
import { UxBaseModule } from '@helvetia/ng-ux-base/index';

@NgModule({
  imports: [
    UxBaseModule.forRoot()
  ],
  declarations: [ ScreenOverlayNoBarComponent ],
  exports: [ ScreenOverlayNoBarComponent ]
})
export class FwScreenOverlayModule {
}
