import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { InsuranceActivity, InsuranceProduct } from '../../selly-shared-module/models';

@Component({
  selector: 'fw-product-list',
  template: require('./product-list.html'),
  styles: [require('./product-list.scss')],
})
export class ProductListComponent {

  @Input()
  productList: Array<InsuranceProduct> = [];

  @Output()
  selected = new EventEmitter();


  productSelected(product: InsuranceProduct) {
    this.selected.emit(product);
  }

}
