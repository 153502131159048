import { Component } from '@angular/core';
import { NgSellyConfig } from 'ng-selly.config';
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserService, UserType} from "../../../selly-shared-module/auth/user.service";
import { QuotationService } from 'src/selly-app/quotation.service';


@Component({
  selector: 'fw-footer-hi',
  template: require('./helvetiavita.html'),
  styles: [require('./helvetiavita.scss')],
})
export class HelvetiaVitaComponent {
  productCode: string;
  constructor(activatedRoute: ActivatedRoute,
    router: Router, private userService: UserService,
    private config: NgSellyConfig, private quotationService: QuotationService) {
    activatedRoute.params.subscribe((params: Params) => {
      this.productCode = params['productCode'];
    });
  }

  isAgent() {
    return this.userService.isAuthenticated().type === UserType.AGENT;
  }

  goTo(page: any) {
    switch (page) {
      case 'ciPresentiamo':
        window.location.href = 'https://www.helvetia.com/it/web/it/chi-siamo/helvetia/helvetia-in-italia/le-compagnie-del-gruppo/helvetia-vita.html';
        break;
      case 'agenzia':
        window.location.href = 'https://www.helvetia.com/it/web/it/chi-siamo/helvetia/helvetia-in-italia/canali-distributivi/agenzie.html';
        break;
      case 'privacy':
        window.location.href = this.config.apiServer + 'public/privacyVITA.pdf';
        break;
    }
  }

}
