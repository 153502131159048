import { InfoBoxController } from './../../custom-component-module/infoBox-controller/infobox.controller';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs/index';
import { catchError, retry, tap } from 'rxjs/internal/operators';
import { UserService } from '../../selly-shared-module/auth/user.service';
import { Router } from '@angular/router';
import {Inject, Injectable, InjectionToken} from '@angular/core';
import {map, timeout} from "rxjs/operators";
import { AuthenticationService } from 'src/selly-web-module/authentication.service';
import { ErrorService } from './error.service';
export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {



  constructor(private userService: UserService,
              private router: Router,
              private authenticationService: AuthenticationService,
              private infoBoxController: InfoBoxController,
              private errorService: ErrorService,
              @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // === 'customer'  === 'affinity' ?

    const userType = this.authenticationService.isAuthenticated().type
    const timeoutValue = req.headers.get('timeout') || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);
    // possibile custom timeout http.get('/your/url/here', { headers: new HttpHeaders({ timeout: `${20000}` }) })

    if (!window.navigator.onLine) {
      console.log('/ if there is no internet, throw a HttpErrorResponse error\n' +
        '      // since an error is thrown, the function will terminate here')
      let InternetError =  {
          error: {
            errorCode: 'NO_INTERNET'
          }
      }
      this.infoBoxController.show({title: 'Attenzione', html: InternetError.error.errorCode})
      return throwError(InternetError);

    } else {


      let authReq: HttpRequest<any>
      const user = this.userService.isAuthenticated();

      const userId = user && user.loggedInAs && user.loggedInAs['userId']
      const tokenLogin = user && user['loginToken']

      let h = req.headers;
      if (userId)
        h = h.set("x-user-id",userId.toString())
      if (tokenLogin)
        h= h.set("authorization", tokenLogin)

      if(req.url.includes('api/apsa')) {
        // console.log('------------REQUEST', req)
        authReq = req.clone({
          headers: h
        })

      } else {
        authReq = req.clone()
      }
      // console.log('------------authReq', authReq)
        return next.handle(authReq)
        .pipe(timeout(timeoutValueNumeric),
          tap((httpResponse: any) => {
            if (httpResponse instanceof HttpResponse) {
              console.log(`[${httpResponse.status} - ${httpResponse.statusText}] Response from: ${httpResponse.url}`, httpResponse.body);
            }
          }),
          // retry(3),
          catchError((error) => {
            if (error instanceof HttpErrorResponse)
              console.log(`[${error.status} - ${error.statusText}] Response from: ${error.url} - ${error.message}`, error.error);
            if (error.status === 401) {
              console.log(`[${error.status} - ${error.statusText}] ERRORE 401 Response from: ${error.url} - ${error.message}`, error.error);
              let sessionError = {
                error: {
                  errorCode: 'SESSION'
                }
              }
              this.errorService.addErrors(sessionError);
              return throwError(sessionError);

            }
            if (error.status === 0) {
              error = {
                error: {
                  errorCode: 'NO_SERVER'
                }
              }
              return throwError(error);

            }
            return throwError(error);
          })
        );
    }
  }

  goHome(){
    const userType = this.authenticationService.isAuthenticated().type
    this.userService.logout();
      if(userType === 'affinity') {
        this.router.navigate(['selly-4affinity']);
      } else if(userType === 'agent') {
        this.router.navigate(['selly-4agents']);
      } else {
        this.router.navigate(['selly-web']);
      }
  }
}
