import { Component, Input } from '@angular/core';

@Component({
  selector: 'fw-big-box',
  template: require('./big-box.html')
})
export class BigBoxComponent {
  @Input()
  color: 'primary' | 'secondary' | 'white' | 'tertiary' | 'quaternary' | 'pampas' | 'iron' | 'grey' | 'dark-grey' = 'primary';

  @Input()
  size: 'm' | 'full' | 'narrow' | 's' = 's';

}
