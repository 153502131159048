import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: '[fw-sticky-footer-link]',
  template: require('./sticky-footer-link.html'),
  styles: [require('./sticky-footer-link.scss')],
  host: { 'class': 'uxa-m-flex-column' }
})
export class StickyFooterLinkComponent {

  @Input()
  label: string = 'LABEL';

  @Input()
  color: string = 'primary';

  @Input()
  href: string = '';

  @ViewChild('anchor', { static: false })
  anchor: ElementRef;

  _columns: number = 6;
  @Input()
  set columns(newValue) {
    this.render.removeClass(this.el.nativeElement, 'uxa-m-flex-column--size-' + this._columns);
    this.render.addClass(this.el.nativeElement, 'uxa-m-flex-column--size-' + newValue);
  }

  get columns() {
    return this._columns;
  }

  constructor(private el: ElementRef, private render: Renderer2) {
  }

  goToExternalLink() {
    window.open(this.href, '_blank');
  }

}
