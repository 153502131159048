import { Component } from '@angular/core';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import { zip } from 'rxjs';
import { take } from 'rxjs/internal/operators';
import {QuotationService} from "../quotation.service";

@Component({
  selector: 'fw-redirect',
  template: `<h1></h1>`
})
export class RedirectComponent {
  constructor(router: Router, activatedRoute: ActivatedRoute, private quotationService: QuotationService) {
    const queryParamsObserver = activatedRoute.queryParams.pipe(take(1));
    const dataObserver = activatedRoute.data.pipe(take(1));
    const concatObserver = zip(queryParamsObserver, dataObserver);

    concatObserver.subscribe((input: Array<Params | Data>) => {
      let param = input[0].product ? input[0] : input[1];
      let data = input[0].product ? input[1] : input[0];
      
      switch (data.page) {
        case 'selly-4affinity-abandoned-payment':

          const session = this.quotationService.currentRequest;
          const originQuote = session.variables['originQuote'];
          console.log(originQuote);
          router.navigate([{
            outlets:
              {
                primary: ['selly-4affinity', 'preventivo', `${originQuote}`],
                header: ['header' , { header: 'APSA_ACQUISTO_PREVENTIVO_ABBANDONATO' }],
                footer: ['footer' , 'helvetiaitalia']
              }}
          ]);
          break;
        // case '1':
        //
        //   break;
        default:

        router.navigate([{
          outlets:
            {
              primary: [data.urlRoot, 'wizard', param.product, 'step1'],
              header: ['header' , param.product],
              footer: ['footer' , param.product],
            }}
        ], {preserveQueryParams: true});
      }
    });
  }
}
