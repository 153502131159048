import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { SaveForLateComponent } from './save-for-late.component';
import { QuotationRequest } from '../../selly-shared-module/models';
import { QuotationService } from '../../selly-app/quotation.service';
import moment = require('moment');
import { isoFormatDate } from '../utils/costants';

@Injectable()
export class SaveForLateService {

  private viewContainerRef: ViewContainerRef;
  private componentRef: ComponentRef<SaveForLateComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private quotationService: QuotationService) {}

  setRootViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
    this.setupComponent();
  }

  setupComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SaveForLateComponent);
    const viewContainerRef = this.viewContainerRef;
    this.componentRef = viewContainerRef.createComponent(componentFactory);
  }

  checkForSavedData() {
    let _saveForLate: string = localStorage.getItem('saveForLate');
    if (_saveForLate === null) return;

    try {
      const saveForLate: SavedForLate = JSON.parse(_saveForLate);
      this.componentRef.instance.showOverlaySaveForLateUseCurrent();


    } catch (e) {
      console.error('Error retrieving data from storage');
    }
  }

  saveForLate() {
    let today = moment().format(isoFormatDate);

    let toSave: SavedForLate = {
      date: today,
      currentRequest: this.quotationService.currentRequest,
      page: window.location.href,
      steps: sessionStorage.getItem('wizardStatus')
    };
    localStorage.setItem('saveForLate', JSON.stringify(toSave));
    this.componentRef.instance.showOverlaySaveForLateSaved();
  }

}

export interface SavedForLate {
  page: string;
  date: string;
  currentRequest: QuotationRequest;
  steps: any;
}
