import { Component, ElementRef, Input, Renderer2 } from '@angular/core';

@Component({
  selector: '[fw-sticky-footer-text]',
  template: require('./sticky-footer-text.html'),
  styles: [require('./sticky-footer-text.scss')],
  host: { 'class': 'uxa-m-flex-column' }
})
export class StickyFooterTextComponent {

  @Input()
  label: string = 'LABEL';

  @Input()
  color: string = 'primary';

  _columns: number = 6;
  @Input()
  set columns(newValue) {
    this.render.removeClass(this.el.nativeElement, 'uxa-m-flex-column--size-' + this._columns);
    this.render.addClass(this.el.nativeElement, 'uxa-m-flex-column--size-' + newValue);
  }
  get columns() {
    return this._columns;
  }


  constructor(private el: ElementRef, private render: Renderer2) {
  }

}
