import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {NotFoundPageComponent} from './selly-app/not-found-page/not-found-page.component';
import {HeaderComponent} from './selly-app/header/header.component';
import {FooterComponent} from './selly-app/footer/footer.component';
import {AffinityGuard, AgentsGuard, LiquidatoreGuard, ThankYouPageGuard} from './selly-shared-module/auth/agents.guard';
import {AuthComponent, AuthSecurityComponent, LoginComponent} from './selly-shared-module/auth/user.service';
import {HelvetiaItaliaComponent} from './selly-app/footer/hi/helvetiaitalia.component';
import {NotOperationalPageComponent} from "./selly-app/not-operational/not-operational-page.component";
import {RedirectComponent} from "./selly-app/redirect/redirect.component";
import {OutOfOrderPageComponent} from "./selly-app/out-of-order/out-of-order-page.component";
import {HelvetiaVitaComponent} from './selly-app/footer/hvita/helvetiavita.component';


const appRoutes: Routes = [
  {
    path: 'selly-web',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./selly-web-module/selly-web.module')['SellyWebModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
  },
  {
    path: 'selly-4agents',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./selly-4agents-module/selly-4Agents.module')['Selly4AgentsModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    canActivate: [AgentsGuard]
  },
  {
    path: 'selly-4agents/logout',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./selly-app/logout/logout.module')['LogoutModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    canActivate: [AgentsGuard]
  },
  {
    path: 'selly-support',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./selly-support-module/selly-support.module')['SellySupportModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    canActivate: [LiquidatoreGuard]
  },
  {
    path: 'selly-4rental',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./selly-4rental-module/selly-4rental.module')['Selly4rentalModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) }
  },
  {
    path: 'selly-4affinity',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./selly-4affinity-module/selly-4affinity.module')['Selly4affinityModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    canActivate: [AffinityGuard]
  },
  {
    path: 'not-operational',
    component: NotOperationalPageComponent
  }, {
    path: 'out-of-order',
    component: OutOfOrderPageComponent
  },
  {
    path: 'thankyou',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./selly-app/thankyou/thankyou.module')['ThankyouModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    canActivate: [ThankYouPageGuard],
    data: {
      stats: {
        user: {
          profile: {
            profileInfo: {
              type: ''
            }
          }
        },
        page: {
          category: {
            formName: '',
            siteName: 'selly',
            siteSection1: '%PRODUCT%',
            siteSection2: 'pagamento:successo',
            siteSection3: ''
          },
          pageInfo: {
            pageName: 'selly:%PRODUCT%:pagamento:successo',
            country: 'it',
            language: 'it'
          }
        },
        product: {
          productInfo: {
            productCategory:'',
            productName: '',
            purchaseDetails: '',
            productInsurancePremium: '',
            productInsuranceSum: '',
            preventivoID: ''
          }
        }
      }
    }
  },
  {
    path: 'thankyou-page',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./selly-app/thankyou-link/thankyou-link.module')['ThankyouLinkModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
    data: {
      stats: {
        user: {
          profile: {
            profileInfo: {
              type: ''
            }
          }
        },
        page: {
          category: {
            formName: '',
            siteName: 'selly',
            siteSection1: '%PRODUCT%',
            siteSection2: 'pagamento:successo',
            siteSection3: ''
          },
          pageInfo: {
            pageName: 'selly:%PRODUCT%:pagamento:successo',
            country: 'it',
            language: 'it'
          }
        }
      }
    }
  },
  {
    path: 'sos/:productCode',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./selly-app/sossinistri/sossinistri.module')['SossinistriModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
  },
  {
    path: 'ci_presentiamo',
    loadChildren: function() { return new Promise(function (resolve, reject) {  (require as any).ensure([], function (require: any) {    resolve(require('./selly-app/cipresentiamo/cipresentiamo.module')['CipresentiamoModule']);  }, function(e: any) {    reject({ loadChunkError: true, details: e });  });}) },
  },
  {
    path: 'redirect',
    component: RedirectComponent,
  },
  {
    path: 'header',
    component: HeaderComponent,
    outlet: 'header'
  },
  {
    path: 'header/:productCode',
    component: HeaderComponent,
    outlet: 'header'
  },
  {
    path: 'footer',
    component: FooterComponent,
    outlet: 'footer'
  },
  {
    path: 'helvetiaitalia',
    component: HelvetiaItaliaComponent,
    outlet: 'footer'
  },
  {
    path: 'footer/TCM',
    component: HelvetiaVitaComponent,
    outlet: 'footer'
  },
  {
    path: 'footer/:productCode',
    component: FooterComponent,
    outlet: 'footer'
  },
  {path: '', redirectTo: '/selly-web/catalog(header:header//footer:footer)', pathMatch: 'full'},
  {path: '**', component: NotFoundPageComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false, // <-- debugging purposes only
      }
    )
  ],
  declarations: [
    AuthComponent, AuthSecurityComponent, LoginComponent
  ],
  entryComponents: [
    AuthComponent, AuthSecurityComponent, LoginComponent
  ],
  exports: [
    RouterModule
  ]
})
export class SellyAppRoutes {
  constructor() {
  }
}
