import { Component } from '@angular/core';
import { UserService } from '../../selly-shared-module/auth/user.service';
import { NgSellyConfig } from 'ng-selly.config';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'fw-username',
  template: require('./user-name.html'),
  styles: [require('./user-name.scss')],
})
export class UserNameComponent {
  originName: string
  route: string
  constructor(public userService: UserService, private config: NgSellyConfig, private router: Router) {
    this.originName = 'selly4affinity'

    this.route = router.url
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      this.route = event['url'];
    });
  }

  logout() {
    sessionStorage.clear();
    window.location.href = this.config.affinityLoginUrl + '/logout';
  }

  goToPersonalArea() {
    window.location.href = `${this.config.signUp}area-personale/?origin=${this.originName}`;
  }

  goToProducts() {
    this.router.navigateByUrl('/selly-web');
  }
  goToAffinity() {
    console.log("/affinity")
    window.location.href = '/selly-4affinity';
    // this.router.navigateByUrl('/selly-4affinity');
  }

  policiesHome(){
    this.router.navigateByUrl("/selly-web/home(header:header//footer:footer)");
  }
}
