import { Component, OnInit } from '@angular/core';
import moment = require('moment');
import { UserType } from "../../selly-shared-module/auth/user.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgSellyConfig } from "ng-selly.config";
import { take } from "rxjs/internal/operators";

@Component({
  selector: 'fw-not-operational',
  template: require('./not-operational-page.html'),
  styles: [require('./not-operational-page.scss')],
})
export class NotOperationalPageComponent implements OnInit {

  message: string;
  reason: string;

  // L’orario d’esercizio è dalle 8.00 alle 20.00 dal lunedì al sabato.
  constructor(private router: Router, private config: NgSellyConfig, private activeRoute: ActivatedRoute,) {
    console.log(this.activeRoute.queryParams);
    this.activeRoute.queryParams.subscribe(params => {
      if (params && params.reason) {
        this.reason = params.reason;
      }
    });
  }

  ngOnInit() {

    let now = moment().format('HH:mm:ss');
    let dayNumber = moment().isoWeekday();
    let beforeStart = moment(this.config.sistemStart, 'HH:mm:ss').subtract(1, "hour").format('HH:mm:ss');
    let sistemStartFormatted = moment(this.config.sistemStart, 'HH:mm:ss').format('HH:mm');
    let sistemEndFormatted = moment(this.config.sistemEnd, 'HH:mm:ss').format('HH:mm');

    // a breve i sistemi si attivano
    if (this.reason === 'maintenance'){
      if (this.config.closingDay.includes(dayNumber)){
        this.message = `Il sito è in manutenzione nelle giornate di Sabato e Domenica. Torna Lunedi. Ti aspettiamo.`;
      }
    } else if (this.reason === 'time') {
      if (this.config.closingDay.includes(dayNumber) && (now >= beforeStart && now < this.config.sistemStart)) {
        this.message = `Siamo spiacenti, i sistemi saranno disponibili a breve. Il servizio sarà attivo dalle ore ${sistemStartFormatted} alle ore ${sistemEndFormatted}. Grazie per la pazienza.`;
      } else {
        this.message = `Siamo spiacenti, i sistemi sono attivi dalle ore ${sistemStartFormatted} alle ore ${sistemEndFormatted} dal lunedì al sabato. Grazie per la pazienza.`;
      }
    } else if (this.reason === 'agent') {
      this.message = `Siamo spiacenti, ma Internet Explorer non supporta questo servizio. Utilizza uno dei seguenti browser: Chrome, Firefox ed Edge. Grazie per la
pazienza`;
    }
  }


}
