import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UserService, UserType } from '../../selly-shared-module/auth/user.service';
import { NgSellyConfig } from 'ng-selly.config';
import { QuotationService } from '../quotation.service';

@Component({
  selector: 'fw-footer',
  template: require('./footer.html'),
  styles: [require('./footer.scss')],
})
export class FooterComponent {
  productCode: string;
  footer: string;

  constructor(activatedRoute: ActivatedRoute, private userService: UserService,
    private config: NgSellyConfig, private quotationService: QuotationService) {
    if (this.userService.isAuthenticated().type === UserType.CUSTOMER)
      this.footer = ` - L'impresa opera attraverso il sito in qualità di distributore di prodotti assicurativi ·  Via Cassinis, 21 · 20139 Milano (MI)`;
    activatedRoute.params.subscribe((params: Params) => {
      this.productCode = params['productCode'];
    });    

  }

  isAgent() {
    return this.userService.isAuthenticated().type === UserType.AGENT;
  }

  goTo(page) {
    switch (page) {
      case 'compagnie':
        window.location.href = 'https://www.helvetia.com/it/web/it/chi-siamo/helvetia/helvetia-in-italia/le-compagnie-del-gruppo.html';
        break;
      case 'ciPresentiamo':
        window.location.href = '/ci_presentiamo(header:header//footer:footer)';
        break;
      case 'sos':
        window.location.href = '/sos/' + this.productCode + '(header:header//footer:footer)';
        break;
      case 'privacy':
        window.location.href = this.config.apiServer + 'public/privacy.pdf';
        break;
    }
  }

}
