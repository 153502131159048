import {Component, OnInit} from '@angular/core';
import moment = require('moment');
import {UserType} from "../../selly-shared-module/auth/user.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {NgSellyConfig} from "ng-selly.config";
import {take} from "rxjs/internal/operators";

@Component({
  selector: 'fw-out-of-order',
  template: require('./out-of-order-page.html'),
  styles: [require('./out-of-order-page.scss')],
})
export class OutOfOrderPageComponent implements OnInit {

  message: string;
  greetings: string;
  iconProcess = '/assets/img/icon_process.svg';
  iconManagers = '/assets/img/icon_construction_manager.svg';
  // L’orario d’esercizio è dalle 8.00 alle 20.00 dal lunedì al sabato.
  constructor(private router: Router, private config: NgSellyConfig, private activeRoute: ActivatedRoute,) {

  }

  ngOnInit() {


    this.message = `I nostri esperti stanno lavorando per migliorare il servizio...`;
    this.greetings = `Torneremo il prima possibile!!`;
  }
  backHome() {
    window.location.href ='http://www.agenziapadana.it/';
  }

}
