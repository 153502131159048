import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserNameComponent } from './user-name.component';

@NgModule({
  declarations: [ UserNameComponent ],
  exports: [ UserNameComponent ],
  imports: [CommonModule],
})
export class UserNameModule {
}
