import { Observable, ReplaySubject } from 'rxjs/index';
import { Injectable } from '@angular/core';
import { NgSellyConfig } from 'ng-selly.config';


@Injectable()
export class GoogleMapAutocompleteProvider {
  googleMapsUrl = '';
  googleReadyStatus: ReplaySubject<boolean> = new ReplaySubject();

  constructor(config: NgSellyConfig) {
    this.googleMapsUrl = 'https://maps.googleapis.com/maps/api/js?key=' + config.googleApiKey + '&callback=googleMapAutocomplete&libraries=places&language=IT';
    this.addGoogleMapsScript();
  }

  doMapInitLogic() {
    this.googleReadyStatus.next(true);
  }

  getStatus(): Observable<boolean> {
    return this.googleReadyStatus.asObservable();
  }

  addGoogleMapsScript() {
    if (!document.querySelectorAll(`[src="${this.googleMapsUrl}"]`).length) {

      window['googleMapAutocomplete'] = () => {
        console.log('LOADED');
      };

      document.body.appendChild(Object.assign(
        document.createElement('script'), {
          type: 'text/javascript',
          src: this.googleMapsUrl,
          onload: () => this.doMapInitLogic()
        }));
    } else {
      this.doMapInitLogic();
    }
  }
}

