import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/index';

export enum SpinnerStatus {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE'
}

export interface SpinnerConfig {
  status: SpinnerStatus;
  text?: string;
}

@Injectable()
export class SpinnerController {

  private obs: Subject<SpinnerConfig> = new Subject<SpinnerConfig>();

  show(text?: string) {
    this.obs.next({status: SpinnerStatus.OPEN, text: text});
  }

  close() {
    this.obs.next({status: SpinnerStatus.CLOSE} );
  }

  subscribe() {
    return this.obs.asObservable();
  }
}
