import { Policy } from './../selly-shared-module/models';
import { Injectable } from '@angular/core';
import { PhysicalPeople, PolicyHolder, QuotationRequest, Survey, Insurable } from '../selly-shared-module/models';
import * as _ from 'lodash';
import { WizardConfigService } from '../remote-service-module/wizard-config/wizard-config.service';
import { UserService } from '../selly-shared-module/auth/user.service';

@Injectable()
export class QuotationService {
  //Year for footer copyright
  yearCopyright = Date.now();

  private _currentRequest: QuotationRequest;

  constructor(
    private wizardService: WizardConfigService,
    private userService: UserService,
    ) { }

  init(request: QuotationRequest) {
    this._currentRequest = JSON.parse(JSON.stringify(request));
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest));
  }

  public get currentRequest(): QuotationRequest {
    // if (!this._currentRequest)
    this._currentRequest = JSON.parse(sessionStorage.getItem('quotation')) as QuotationRequest;
    return this._currentRequest;
  }

  public getDefaultValue(key: string | string[], defaultValue: string, postProcess?: Function) {
    let value = _.get(this.currentRequest, ['variables'].concat(key), defaultValue);

    if (value === defaultValue || _.isNil(postProcess))
      return value;

    return postProcess(value);
  }

  public loadQuotation(quotation: QuotationRequest){
    sessionStorage.removeItem('wizardStatus');
    const _quotation = quotation['quote'] ? quotation['request'] : quotation
    let wizardSteps = this.wizardService.getWizardByCode(_quotation.productCode);
    console.log('[QuotationService][loadQuotation]', _quotation.productCode);
    wizardSteps.forEach(step => {
      step['enabled'] = true
    })
    sessionStorage.setItem('wizardStatus', JSON.stringify(wizardSteps));
    this._currentRequest = quotation;
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest));
    return this._currentRequest;
  }


  public loadPolicy(policy: Policy){
    sessionStorage.removeItem('wizardStatus');
    let wizardSteps = this.wizardService.getWizardByCode(policy.productCode);
    console.log('[QuotationService][loadPolicy]', policy);
    wizardSteps.forEach(step => {
      step['enabled'] = true
    })
    sessionStorage.setItem('wizardStatus', JSON.stringify(wizardSteps));
    sessionStorage.setItem('quotation', JSON.stringify(policy));
  }

  public newQuotation(productCode: string, productNode: string, visitorId?: string): QuotationRequest {
    console.log('[QuotationService][newQuotation]');
    sessionStorage.removeItem('wizardStatus');

    let wizardSteps = this.wizardService.getWizardByCode(productCode);

    console.log('[wizardSteps][newQuotation]', wizardSteps);
    console.log('[QuotationService][newQuotation]', productCode);
    let step1 = wizardSteps.find((e) => e.code === 'step1');
    _.set(step1, 'enabled', true);
    sessionStorage.setItem('wizardStatus', JSON.stringify(wizardSteps));

    this._currentRequest = {
      referer: sessionStorage.getItem('referrer'),
      visitorId: visitorId,
      productCode: productCode,
      packageCode: 'DEFAULT',
      productNode: productNode,
      variables: {},
      guarantees: {},
      insured: [],
      asShop: {}
    };
    if (this.userService.isAuthenticated().shop)
      this._currentRequest.asShop = this.userService.isAuthenticated().shop.code;

    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest));
    return this._currentRequest;
  }

  public changeVehicleType(productCode: string, productNode: string, insured: Insurable, visitorId?: string): QuotationRequest {
    console.log('[QuotationService][changeVehicle]');
    sessionStorage.removeItem('wizardStatus');
    sessionStorage.removeItem('listBrands');
    sessionStorage.removeItem('listModels');
    sessionStorage.removeItem('listCarFittings');

    let wizardSteps = this.wizardService.getWizardByCode(productCode);
    let step1 = wizardSteps.find((e) => e.code === 'step1');
    let step2 = wizardSteps.find((e) => e.code === 'step2');
    _.set(step1, 'enabled', true);
    _.set(step2, 'enabled', true);
    sessionStorage.setItem('wizardStatus', JSON.stringify(wizardSteps));

    this._currentRequest.insured = [insured];
    this._currentRequest.variables = {};

    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest));
    sessionStorage.removeItem('prevVehicle');

    return this._currentRequest;
  }

  public clearQuotation() {
    this._currentRequest = undefined;
  }

  public setAgeDescription(ageDescription: string) {
    this._currentRequest.ageDescription = ageDescription;
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest));
  }

  public setAgent(ageDescription: any) {
    this._currentRequest.agent = ageDescription;
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest));
  }

  public addGuarantee(name: string, value: any) {
    console.log('[QuotationService][addGuarantee]', name, value);
    this._currentRequest.guarantees[name] = value;
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest));
  }

  public removeGuarantee(name: string) {
    delete this._currentRequest.guarantees[name];
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest));
  }

  public checkGuarantee(name: string) {
    return this._currentRequest.guarantees[name];
  }

  public addVariable(name: string, value: any) {
    console.log('[QuotationService][addVariable]', name, value);
    this._currentRequest.variables[name] = value;
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest));
  }

  public removeVariable(name: string) {
    console.log('[QuotationService][removeVariable]', name);
    delete this._currentRequest.variables[name];
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest));
  }

  public setPolicyHolder(policyHolder: PolicyHolder) {
    this._currentRequest.policyHolder = policyHolder;
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest));
  }

  public setInsured(list: Array<Insurable>) {
    this._currentRequest.insured = list;
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest));
  }

  public setPackageCode(value: string) {
    this._currentRequest.packageCode = value;
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest));
  }

  public setQuotationNumber(value: string) {
    this._currentRequest.quotationNumber = value;
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest));
  }

  // public setDiscount(value: number) {
  //   this._currentRequest.variables.priceDiscount = value;
  //   sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest));
  // }

  public setSurvey(value: Survey | null) {
    this._currentRequest.survey = _.cloneDeep(value);
    sessionStorage.setItem('quotation', JSON.stringify(this._currentRequest));
  }

  public setPrevVehicle(vehicle: Insurable) {
    // tslint:disable-next-line:no-unused-expression
    vehicle && sessionStorage.setItem('prevVehicle', JSON.stringify({ type: vehicle.type }));
  }
}
