import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/index';
import { UserService } from '../../selly-shared-module/auth/user.service';
import {Injectable} from '@angular/core';
import { AuthenticationService } from 'src/selly-web-module/authentication.service';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {


  constructor(private authenticationService: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let user = this.authenticationService.user

    const userId = user.loggedInAs? user.loggedInAs.userId : user.notLoggedUserId;
    const tokenLogin = user.loginToken
    let h = req.headers;
    if (userId)
      h = h.set("x-user-id",userId.toString())
    if (tokenLogin)
      h= h.set("authorization",tokenLogin)

    const authReq = req.clone({
      headers: h
    })
    return next.handle(authReq)
  }
}
