import { ScreenOverlayComponent } from '@helvetia/ng-ux-base/src/lib/screen-overlay/screen-overlay.component';
import {Component, Input} from '@angular/core';


@Component({
  selector: 'fw-screen-overlay-nobar',
  template: require('./screen-overlay.html'),
  styles: [require('./screen-overlay.scss')],
})
export class ScreenOverlayNoBarComponent extends ScreenOverlayComponent {
  // @Input() icon: string;
  @Input() errorCode: {
    icon?: string;
    title?: string;
    html: string;
  };
}
