import { ERROR_CODE_MESSAGE_MAP } from './errors.enum';
import { Injectable } from "@angular/core";


@Injectable()
export class ErrorHandlerService {

    getError(errorCode?: string, defaultMessage?: string): ERROR_CODE_MESSAGE_MAP {
        if (errorCode && errorCode in ERROR_CODE_MESSAGE_MAP) {
            return ERROR_CODE_MESSAGE_MAP[errorCode];
        }

        if (defaultMessage) {
            return {
                icon: ERROR_CODE_MESSAGE_MAP.DEFAULT.icon,
                title: ERROR_CODE_MESSAGE_MAP.DEFAULT.title,
                html: defaultMessage
            }
        }

        return ERROR_CODE_MESSAGE_MAP.DEFAULT;
    }

}
