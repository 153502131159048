import { Injectable } from '@angular/core';
import { Premium, PremiumRequest, QuotationRequest } from '../../selly-shared-module/models';
import { SpinnerController } from '../../custom-component-module/spinner-controller/spinner.controller';
import { NgSellyConfig } from 'ng-selly.config';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/internal/operators';
import moment = require("moment");
import { QuotationService } from 'src/selly-app/quotation.service';
import { isoFormatDate } from 'src/custom-component-module/utils/costants';

@Injectable()
export class PremiumService {

  constructor(
    protected spinnerController: SpinnerController,
    private config: NgSellyConfig,
    private http: HttpClient,
    private quotationService: QuotationService) {
  }

  public getPremium(premiumRequest: PremiumRequest, store: boolean = false): Promise<Premium> {
    setTimeout(() => this.spinnerController.show('Calcolo il premio...'), 0)
    return new Promise<Premium>((resolve, reject) => {
      this.http.post<Premium>(`${this.config.apiServer}api/premio?store=${store}`, premiumRequest, {
        withCredentials: true,
      }).pipe(take(1))
        .subscribe((premio: Premium) => {
          resolve(premio);
        }, (error: any) => {
          reject();
        }, () => this.spinnerController.close());
    });
  }

  public remarketing(premiumRequest: QuotationRequest): Promise<Premium> {
    return new Promise<Premium>((resolve, reject) => {
      this.http.post<Premium>(`${this.config.apiServer}api/trackRemarketing`, premiumRequest, {
        withCredentials: true,
      }).pipe(take(1)).subscribe((_) => {
        resolve();
      }, reject);
    });
  }

  uploadTCMUser(user) {
    return this.http.post<any>(this.config.apiServer + 'apiV2/proxy/lead-acquisition', user, {
      observe: 'response',
      withCredentials: true
    });
  }

  verifyDocument(userId: any) {
    return new Promise<any>((resolve, reject) => {
      this.http.get<any>(`${this.config.apiServerK8s}auth/btc/verifyDocument/${userId}/identity_card/approved`)
        .pipe(take(1)).subscribe((res: any) => {
          resolve(res);
          //this.spinnerController.close();
        }, (error: any) => {
          reject();
          //this.spinnerController.close();
        });
    })
  }
  // dato che i pagamenti sono entrambi rateali (anche annuale sono 3 rate 1 ogni anno) calcolo l'annualita' della polizza attiva
  yearCount() {
    let toDay = moment().format("YYYY-MM-DD") as string;
    const decorrenza: string = this.quotationService.currentRequest.variables['DECORRENZA'] as string;

      let days = moment(toDay, isoFormatDate).diff(moment(decorrenza, isoFormatDate), 'd')
      let yearDays = 365
      let year = days !== 0 && days % yearDays === 0 ?  Math.floor(days / yearDays) :  Math.floor(days / yearDays) + 1

      console.log('Differenza giorni.........', days)
      console.log('annualità.........', Math.floor(days / yearDays))
      console.log('ANNUALITA.........', year)
      return year


  }
  // per il pagamento mensilizzato calcolo la penultima e ultima rata
  lastTwoAnnualInstallments(yearCount: number) {
    let secondLastAnnualInstallment = (yearCount * 12) - 3
    let lastAnnualInstallment = (yearCount * 12) - 2
    let secondLastAnnualInstallmentDate = moment(this.quotationService.currentRequest['scheduledPayments'][secondLastAnnualInstallment].accountingDate).format("YYYY-MM-DD")
    let lastAnnualInstallmentDate = moment(this.quotationService.currentRequest['scheduledPayments'][lastAnnualInstallment].accountingDate).format("YYYY-MM-DD")
    return [secondLastAnnualInstallmentDate, lastAnnualInstallmentDate]
  }
}

@Injectable()
export class PremiumMockService extends PremiumService {

  public getPremium(premiumRequest: PremiumRequest): Promise<Premium> {
    console.log(premiumRequest);
    this.spinnerController.show('Calcolo il premio...');
    const price = Math.ceil(Math.random() * 10000) / 100;
    const result = {
      'guarantees': [{
        'code': 'OKTRAVEL-ASSISTENZA-SANITARIA',
        'description': 'Assistenza 24 ore su 24 per qualsiasi urgenza.',
        'name': 'Assistenza sanitaria "All Risks" & Lifestyle ',
        'totalAmount': 16,
        'totalTax': 1.45,
        'totalNet': 14.55,
        'price': 16,
        'taxes': 1.45,
        'net': 14.55,
        'taxPerc': 0.1
      }, {
        'code': 'OKTRAVEL-9000-RIMBORSO-DIRETTO',
        'description': 'Pagamento diretto delle spese mediche ospedaliere e chirurgiche non procrastinabili.',
        'name': 'Spese mediche in viaggio',
        'totalAmount': 55,
        'totalTax': 1.34,
        'totalNet': 53.66,
        'price': 55,
        'taxes': 1.34,
        'net': 53.66,
        'taxPerc': 0.025,
      }], 'price': price, 'taxes': 2.79, grossPrice: price
    };


    return new Promise<Premium>((resolve) => {
      setTimeout(() => {
        this.spinnerController.close();
        resolve(result);
      }, 1000);
    });
  }
}
