import {Component} from '@angular/core';
import {InsuranceProduct} from '../../selly-shared-module/models';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ProductListService} from '../../remote-service-module/product-list/product-list.mock-service';
import {Location} from '@angular/common';
import * as _ from 'lodash';
import {HeadlineService} from '../../remote-service-module/headline/headline.service';
import {UserService, UserState, UserType} from 'src/selly-shared-module/auth/user.service';
import { AuthenticationService } from 'src/selly-web-module/authentication.service';


enum HeaderTypes {
  ABSENT = 'ABSENT',
  BASED_ON_PRODUCT = 'BASED_ON_PRODUCT',
  CUSTOM = 'CUSTOM'
}

@Component({
  selector: 'fw-header',
  template: require('./header.html'),
  styles: [require('./header.scss')],
})
export class HeaderComponent {
  headers = {
    APSA_PREVENTIVI: {
      line1: 'I miei preventivi',
      line2: '',
      iconName: 'car'
    },
    APSA_ACQUISTO_PREVENTIVO: {
      line1: 'Acquisto polizza',
      line2: '',
      iconName: 'car'
    },
    APSA_NUOVO_PREVENTIVI: {
      line1: 'Nuovo preventivo',
      line2: '',
      iconName: 'document'
    },
    APSA_ACQUISTO_PREVENTIVO_ABBANDONATO: {
      line1: 'Acquisto polizza',
      line2: '',
      iconName: 'car'
    },
    AGENTS_VENDITA: {
      iconName: 'document'
    },
    AGENTS: {
      iconName: 'profile'
    },
    APSA_POST_ACQUISTO: {
      line1: 'Helvetia Affinity Auto',
      line2: 'Conferma',
      iconName: 'car'
    },
  };

  productInfo: InsuranceProduct;
  headerType: HeaderTypes = HeaderTypes.ABSENT;
  headerTypes = HeaderTypes;
  line1: string;
  line2: string;
  iconName: string;
  is4Affinity: boolean = false;
  previousUrl: string = null;
  customHeader;
  isLoggedUser = false;
  user: UserState;
  isCustomer: boolean = false;
  isThankPage: boolean = false;

  constructor(
    private router: Router,
    private location: Location,
    headlineService: HeadlineService,
    activatedRoute: ActivatedRoute,
    private productListService: ProductListService,
    userService: UserService, private authService: AuthenticationService) {
    this.is4Affinity = userService.isAuthenticated().logged && userService.isAuthenticated().type === UserType.AFFINITY;
    this.isCustomer = userService.isAuthenticated().logged && userService.isAuthenticated().type === UserType.CUSTOMER;

      activatedRoute.params.subscribe((params: Params) => {

        if (!_.isNil(params['productCode'])) {
          this.headerOnProductCode(params['productCode']);
          this.headerType = HeaderTypes.BASED_ON_PRODUCT;
        } else if (!_.isNil(params['header'])) {
          this.headerOnCustom(params['header']);
          this.headerType = HeaderTypes.CUSTOM;
          console.log( this.headerType);
        } else {
          this.headerType = HeaderTypes.ABSENT;
        }
    });

    headlineService.getObserver().subscribe((newValue: string) => {
      this.line2 = newValue;
    });

    this.previousUrl = document.referrer;
    // console.log('---------------------------', document.referrer)

    this.authService.currentUser.subscribe((user) => {
      console.log(user)
      if (user && user.loggedInAs) {
        this.isLoggedUser = true;
      } else {
        this.isLoggedUser = false;
      }
      return (this.user = user);
    });

    this.isThankPage = this.router.url.includes('/thankyou')
  }

  headerOnProductCode(productCode: string) {
    this.productListService.getByCode(productCode).then((productInfo: InsuranceProduct) => {
      this.line1 = productInfo.name;
      this.iconName = productInfo.iconName.toLowerCase();
      this.line2 = productInfo.description;
    });
  }

  headerOnCustom(header: any) {
    console.log(header)
    this.customHeader = header;
    this.line1 = this.headers[header].line1;
    this.line2 = this.headers[header].line2;
    this.iconName = this.headers[header].iconName;
  }

  back() {
    switch (this.customHeader) {
      case 'APSA_ACQUISTO_PREVENTIVO_ABBANDONATO':

        console.log('back');
        this.router.navigate([{
          outlets: {
            primary: ['selly-4affinity', 'preventivi'],
            header: ['header', {header: 'APSA_PREVENTIVI'}],
            footer: ['helvetiaitalia']
          }
        }]);
        break;
      case 'APSA_PREVENTIVI':

        this.router.navigate([{
          outlets: {
            primary: ['selly-4affinity', 'area-personale'],
            header: ['header', {header: 'APSA_ACQUISTO_PREVENTIVO'}],
            footer: ['helvetiaitalia']
          }
        }]);
        break;
      default:
        if (this.previousUrl && this.previousUrl.includes('https://test.epheso.it/')) {
          this.router.navigate([{
            outlets:
              {
                primary: ['selly-4agents'],
                header: ['header', {header: 'AGENTS'}]
              }
          }]);
        } else {
          this.location.back();
        }
    }

  }

}

