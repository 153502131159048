import {
  ApplicationRef,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  LOCALE_ID,
  NgModule,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { SellyAppRoutes } from "./selly-app.routes";
import { FooterComponent } from "./selly-app/footer/footer.component";
import { LinksComponent } from "./selly-app/footer/links/links.component";
import { NotFoundPageComponent } from "./selly-app/not-found-page/not-found-page.component";
import { HeaderComponent } from "./selly-app/header/header.component";
import { SellyAppComponent } from "./selly-app/selly-app.component";
import { NgSellyConfig } from "ng-selly.config";
import { SpinnerController } from "./custom-component-module/spinner-controller/spinner.controller";
import {
  UserMockService,
  UserService,
} from "./selly-shared-module/auth/user.service";
import {
  AffinityGuard,
  AgentsGuard,
  AsShopGuard,
  CheckBeforeLogin,
  LiquidatoreGuard,
  ThankYouPageGuard,
  UserForRemoteSellingGuard,
  UserGuard,
} from './selly-shared-module/auth/agents.guard';
import {QuotationService} from './selly-app/quotation.service';
import {ApiConfigService} from './api-config.service';
import {DEFAULT_TIMEOUT, GlobalHttpInterceptor} from './selly-app/utils/GlobalHttpInterceptor';
import {AuthHttpInterceptor} from './selly-app/utils/AuthHttpInterceptor';
import {Router} from '@angular/router';
import {registerLocaleData} from '@angular/common';
import {UxBaseModule} from '@helvetia/ng-ux-base/index';
import {OsCommonModule} from '@helvetia/ng-oscommon/index';
import {NG_UX_BASE_API_CONFIG} from '@helvetia/ng-ux-base/src/lib/ng-ux-base';
import {NG_OSCOMMON_API_CONFIG} from '@helvetia/ng-oscommon/src/lib/ng-oscommon.ts';
import {SharedModule} from '@helvetia/ng-ux-base/src/demo-app/shared/shared.module';
import localeIt from '@angular/common/locales/it';
import localeExraIt from '@angular/common/locales/extra/it';
import {StickyFooterModule} from './custom-component-module/sticky-footer/sticky-footer.module';
import {ProductListModule} from './custom-component-module/product-list/product-list.module';
import {HeadlineService} from './remote-service-module/headline/headline.service';
import {WizardConfigModule} from './remote-service-module/wizard-config/wizard-config.module';
import {SaveForLateModule} from './custom-component-module/save-for-late/save-for-late.module';
import {IconModule} from './custom-component-module/icon/icon.module';
import {UserNameModule} from './custom-component-module/user-name/user-name.module';
import {FwScreenOverlayModule} from './custom-component-module/fw-screen-overlay/fw-screen-overlay.module';
import {InfoBoxController} from './custom-component-module/infoBox-controller/infobox.controller';
import {HelvetiaItaliaComponent} from './selly-app/footer/hi/helvetiaitalia.component';
import {GoogleMapAutocompleteProvider} from "./custom-component-module/google-map/google-map-autocomplete.provider";
import {NotOperationalPageComponent} from "./selly-app/not-operational/not-operational-page.component";
import {RedirectModule} from "./selly-app/redirect/redirect.module";
import {OutOfOrderPageComponent} from "./selly-app/out-of-order/out-of-order-page.component";
import {HelvetiaVitaComponent} from './selly-app/footer/hvita/helvetiavita.component';
import { SellyWebLoginComponent } from './selly-web-module/login-component/selly-web-login.component';
import { PremiumService } from './remote-service-module/premium/premium.mock-service';
import { ErrorService } from "./selly-app/utils/error.service";
import { ErrorHandlerService } from "./custom-component-module/errors-handler/error-handler.service";

import { take } from "rxjs/operators";
import {HttpClient} from '@angular/common/http';

const loadAdobefactory = (config: NgSellyConfig) => {

  return ()=>{
    return new Promise<any>((resolve, reject) => {
      let script = document.createElement("script");
      script.setAttribute("type", "text/javascript");
      script.setAttribute(
        "src",
        config.adobeUrl
      );

      script.setAttribute("async", 'true');
      script.addEventListener("load", function () {
        if(_satellite){
          resolve('')
        }
      });
      script.addEventListener("error", function () {
        resolve('')
      });
      document.head.appendChild(script);

    });
  };

}

declare global {
  interface Window { SellyAppModule: any; }
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StickyFooterModule,
    UxBaseModule.forRoot(),
    OsCommonModule.forRoot(),
    ProductListModule,
    SellyAppRoutes,
    SharedModule,
    WizardConfigModule,
    SaveForLateModule,
    IconModule,
    UserNameModule,
    FwScreenOverlayModule,
    RedirectModule,
  ],
  declarations: [
    SellyAppComponent,
    HeaderComponent,
    FooterComponent,
    HelvetiaItaliaComponent,
    HelvetiaVitaComponent,
    LinksComponent,
    NotFoundPageComponent,
    NotOperationalPageComponent,
    OutOfOrderPageComponent,
    SellyWebLoginComponent
  ],
  entryComponents: [
    SellyAppComponent
  ],
  providers: [
    NgSellyConfig,
    ErrorHandlerService,
    {provide: APP_INITIALIZER, useFactory: (config: NgSellyConfig) => loadAdobefactory(config), multi: true, deps: [NgSellyConfig] },
    {provide: LOCALE_ID, useValue: 'it'},
    {provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
    {provide: DEFAULT_TIMEOUT, useValue: 60000},
    {provide: NG_UX_BASE_API_CONFIG, useClass: ApiConfigService},
    {provide: NG_OSCOMMON_API_CONFIG, useClass: ApiConfigService},
    QuotationService, SpinnerController,
    InfoBoxController,
    GoogleMapAutocompleteProvider,
    UserGuard,
    AgentsGuard,
    LiquidatoreGuard,
    AsShopGuard,
    UserForRemoteSellingGuard,
    AffinityGuard,
    ThankYouPageGuard,
    {
      provide: UserService,
      useClass: process.env.ENV === "mock" ? UserMockService : UserService,
    },
    HeadlineService,
    CheckBeforeLogin,
    PremiumService,
    ErrorService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SellyAppModule {
  constructor(private _appRef: ApplicationRef, router: Router) {
    localeIt[14][2] = "¤ #,##0.00";
    console.log("Routes: ", router.config, localeIt);
    registerLocaleData(localeIt, "it", localeExraIt);
  }

  ngDoBootstrap() {
    this._appRef.bootstrap(SellyAppComponent);
  }
}
