import { UserState } from "src/selly-shared-module/auth/user.service";
import { AuthenticationService } from "../authentication.service";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { InsuranceProduct, UserB2C } from "../../selly-shared-module/models";
import { ActivatedRoute, Router } from "@angular/router";
import { QuotationService } from "../../selly-app/quotation.service";
import { SpinnerController } from "../../custom-component-module/spinner-controller/spinner.controller";
import {
  UserService,
  UserType,
} from "../../selly-shared-module/auth/user.service";
import { ProductListService } from "../../remote-service-module/product-list/product-list.mock-service";
import { NgSellyConfig } from "ng-selly.config";
import { ScreenOverlayComponent } from "@helvetia/ng-ux-base/src/lib/screen-overlay/screen-overlay.component";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "fw-selly-web-login-component",
  template: require('./selly-web-login.html'),
  styles: [require('./selly-web-login.scss')],
})
export class SellyWebLoginComponent implements OnInit {
  productList: Array<InsuranceProduct> = [];
  @Input() isCustomer: boolean;
  @Input() isLoggedUser: boolean;
  user: UserState;
  originName = "selly4customer";
  isAffinity: boolean

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private quotation: QuotationService,
    private spinnerController: SpinnerController,
    private config: NgSellyConfig,
    private userService: UserService,
    private authService: AuthenticationService,
    private productServiceList: ProductListService,
    private dds: DeviceDetectorService
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.user_login) {
        const user = JSON.parse(atob(params.user_login)); // user
        this.user = this.authService.getUserB2C(user); // transformo l'oggetto user in userB2C
        this.authService.setUser(this.user); // salvo user in sessione
      }
    });

    this.isCustomer =
      userService.isAuthenticated().logged &&
      userService.isAuthenticated().type === UserType.CUSTOMER;
  }

  isMobile() {
    return this.dds.isMobile();
  }

  goToLogin() {
    window.location.href = `${this.config.signUp}accedi/?origin=${this.originName}`;
  }
  logout() {
    this.authService.logout()
  }
  /* logout() {
    this.spinnerController.show();
    this.user.loggedInAs = null;
    this.user.loginToken = null;
    this.user = null
    sessionStorage.setItem("originUrl", '/selly-web/catalog(header:header//footer:footer)');
    sessionStorage.removeItem('userState')
    setTimeout(() => this.anonymousLogin(), 500);
  }

  // torno alla pagina della lista prodotti
  anonymousLogin() {
    this.spinnerController.close();
    let referrer = sessionStorage.getItem('referrer');
    sessionStorage.setItem('referrer', referrer);
    window.location.href = this.config.customerLoginUrl;
    this.router.navigateByUrl('/auth/login');
  } */

  home() {
    this.router.navigateByUrl("/selly-web/home(header:header//footer:footer)");
  }

  goToPersonalArea() {
    window.location.href = `${this.config.signUp}area-personale/?origin=${this.originName}`;
  }

  ngOnInit() {
    this.authService.currentUser.subscribe((user) => {
      if (user && user.loggedInAs) {
        this.isLoggedUser = true;
      } else {
        this.isLoggedUser = false;
      }
      return (this.user = user);
    }); // Always get current value!
  }
}
