import { AddressFormControl } from "src/wizard-module/steps/wizard-registry-user-data/wizard-registry-user-data.component";

export enum InsuranceProductType {
  PRODUCT = "PRODUCT",
  APPENDAGE = "APPENDAGE",
  PRE_SELLING = "PRE-SELLING",
}

export enum InsuranceProductDuration {
  TEMPORARY = "TEMPORARY",
  ANNUAL = "ANNUAL",
}

export interface PaymentMethod {
  type: 'Annual' | 'Recurrent',
}
export interface InsuranceProduct {
  paymentMethods: Array<PaymentMethod>;
  disclaimer?: string;
  helvetiaLink: string;
  maxDuration: number;
  maxiumCoverageAge: number;
  maxAge?: number;
  greetings: string;
  reasonWhy: Array<string>;
  productType: InsuranceProductType;
  type: InsuranceProductDuration;
  iconName: string;
  weight: number;
  code: string;
  name: string;
  description: string;
  longDescription: string;
  status: boolean;
  date_from: number;
  date_to: number | undefined;
  flayers?: any[];
  privacyCollectionPoint?: string
}

export interface InformationSet {
  id: string;
  code: string;
  branch: string;
  description: string;
}

export interface InsuranceActivity {
  name: string;
  path: string;
  iconName: string;
}

export interface Policy extends PolicyRequest {
  motherPolicy?: string;
  totalAmount: number;
  totalAmountTax: number;
  quotationNumber: string;
  status: PolicyStatus;
  proposalNumber: string;
  policyNumber: string;
  duration: number;
  guarantees: { [name: string]: PolicyGuarantee };
  factors: { [name: string]: PolicyFactor };
  dates: PolicyDate;
  datesFormatted: PolicyDate;
  survey: Survey;
  signCode?: string;
  signPoints: Array<SignPoint>;
  printTemplate: string;
  appendages: Array<Appendix>;
  productName?: string;
  signInfo? : {
    jobId: string;
    shortJobUrl: string;
    completed?: boolean;
    sealed?: boolean;
  }
  scheduledPayments?: any

}

/* export enum PolicyStatus {
  ISSUED = "ISSUED",
  EXPORTED = "EXPORTED",
} */

export interface PolicyDate {
  issue: { date: string; hour: string };
  expiry: { date: string; hour: string };
  startCoverage: { date: string; hour: string };
  termination?: { date: string; hour: string };
}

export interface QuotationRequestGuarantee {
  clauses: { [name: string]: string };
}

export interface PolicyGuarantee extends QuotationRequestGuarantee {
  code: string;
  description: string;
  name: string;
  totalAmount: number;
  totalTax: number;
  totalNet: number;
  price: number;
  taxes: number;
  net: number;
  taxPerc: number;
  formulas: { [key: string]: string };
}

export interface PolicyFactor {
  code: string;
  value: string | number;
}

export interface SignPoint {
  signerId: string;
  shortDescription: string;
  longDescription: string;
  isOptional: boolean;
  page: number;
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface Appendix extends AppendixRequest {
  totalAmount: number;
  totalAmountTax: number;
  quotationNumber: string;
  status: AppendixStatus;
  proposalNumber: string;
  appendixNumber: string;
  duration: number;
  guarantees: { [name: string]: PolicyGuarantee };
  dates: PolicyDate;
}

export enum AppendixStatus {
  ISSUED = "ISSUED",
  EXPORTED = "EXPORTED",
}

export interface AppendixRequest extends AppendixQuotationRequest {
  quotationNumber: string;
  policyHolder: PolicyHolder;
}

export interface AppendixQuotationRequest {
  productCode: string;
  packageCode?: string;
  variables: { [name: string]: string | number | boolean };
  guarantees: { [name: string]: QuotationRequestGuarantee };
  policyHolder?: PolicyHolder;
  insured?: Array<PhysicalPeople>;
}

export interface GuaranteesPackage {
  weight: number;
  code: string;
  name: string;
  description: string;
  status: boolean;
  date_from: number;
  date_to: number;
}

export enum ClauseType {
  MASSIMALE = "MASSIMALE",
  LIMITAZIONE = "LIMITAZIONE",
}

export interface ClauseMap {
  [code: string]: Clause;
}

export interface Clause {
  type: "MASSIMALE" | "LIMITAZIONE";
  code: string;
  description: string;
  origDescription?: string;
  name: string;
  value: string;
  factor: number;
}

export interface GenericVariables {
  [variableName: string]: string;
}

export interface PremiumRequest {
  shopCode?: string;
  productCode: string;
  packageCode: string;
  packageName: string;
  guarantees: any;
  visitorid?: string;
  productNode: string;
  variables: GenericVariables;
  insured?: Array<PhysicalPeople>;
  survey?: any;
}

export interface Premium {
  discountApplied?: number;
  guarantees: PremiumGuarantee[];
  quotationNumber?: string;
  submissionNumber?: string;
  grossPrice: number;
  price: number;
  taxes: number;
}

export interface PremiumGuarantee {
  code: string;
  name: string;
  description: string;
  net: number;
  price: number;
  taxPerc: number;
  taxes: number;
  totalAmount: number;
  totalNet: number;
  totalTax: number;
}

export interface Guarantee {
  showReadMore?: boolean;
  sellable?: number;
  infoBox?: string;
  disabled?: boolean;
  weight: number;
  code: string;
  name: string;
  description: string;
  requires: any[];
  excludes: any[];
  clauses: {
    [clauseCode: string]: ClauseMap;
  };
  optional: boolean;
  dataInput?: any;
  parent?: string;
}

export enum ERROR_TYPES {
  EX_0001 = "Missing Parameter not Initialized",
}

export class CustomError extends Error {
  constructor(public code: string, message: string) {
    super(message);
    Object.setPrototypeOf(this, CustomError.prototype);
  }
}

export enum Sex {
  MALE = "M",
  FEMALE = "F",
}

export interface Birth {
  date: string;
  place: string;
  district: string;
  nation: string;
}

export enum InsurableType {
  physical = "physical",
  Vehicle = "Vehicle",
  Motorbike = "Motorbike",
  Bike = "Bike",
  device = "device"
}

export interface Insurable {
  type: InsurableType;
  idx?: number;

}

export interface device extends Insurable {
  imei?: string;
  brand?: string;
  brandName?: string;
  brandCode?: string;
  deviceType: string;
  deviceTypeName?: string;
  model?: string;
  price?: string;
  purchaseDate?: string;
}
export interface PhysicalPeople extends Insurable {
  subSystemCustomerId?:string;
  cb_client_id?:number;
  surname: string;
  firstName: string;
  fiscalCode: string;
  sex: Sex;
  birth: Birth;
  italianResidential: boolean;
}
export interface People {
  name: string;
  surname: string;
  birth: {
    date: string;
    city: string;
    district: string;
    nation: string;
  };
  fiscalCode: string;
  gender: string;
  italianResidence: boolean;
}

export interface Vehicle extends Insurable {
  plate: string;
  secondPlate?: string;
  codiceFiscale2?: string;
  dataRetrieve?: any;
  sivi?: Sivi;
  atrc?: Atrc;
  info?: InfoCar | any;
  type;
  tipoOpzione?;
  dataImmatricolazione?;
  cilindrata?;
  sivi2?: Sivi;
  atrc2?: Atrc;
  provincia?: string;
  motorbikeCilindrata?;
}

export interface Address {
  address: string;
  addressNumber: string;
  zipCode: string;
  city: string;
  district: string;
  nation: string;
}

export interface PolicyHolder extends PhysicalPeople {
  email: string;
  phone?: string;
  postalCode?: string;
  residentialAddress: Address;
  privacyRead?: boolean;
  checkPrivacy?: boolean;
  checkMarketingPrivacy?: boolean;
  checkProfilePrivacy?: boolean;
  terminiUso?: boolean;
  flyerRead: boolean;
  agreement?: string
}

export interface PolicyHolderPurchase {
  email: string;
  phone?: string;
  paperDocuments?: string;
  residentialAddress: {
    address: string;
    zipCode: string;
    city: string;
    district: string;
  };
  secondPlate?: string;
  secondName?: string;
  secondSurname?: string;
}

export interface QuotationRequestGuarantee {
  clauses: { [name: string]: string };
}

export interface Survey {
  result?: string;
  code: string;
  title: string;
  text: string;
  questions: { [code: string]: SurveyQuestion };
}

export enum SurveyQuestionType {
  TEXT = "TEXT",
  GROUP = "GROUP",
  MULTICHOISE = "MULTICHOISE",
  SINGLECHOISE = "SINGLECHOISE",
}

export enum SurveyQuestionResult {
  ADEGUATO = "ADEGUATO",
  NONADEGUATO = "NONADEGUATO",
  NONVALUTABILE = "NONVALUTABILE"
}

export interface SurveyQuestion {
  code: string;
  type: SurveyQuestionType | string;
  title: string;
  text?: string;
  textNONADEGUATO?: string;
  message?: string;
  formula?: { [key: string]: string | boolean } ;
  answers?: { [code: string]: SurveyAnswer };
  textAnswer?: string;
  result?: SurveyQuestionResult;
  questions?: { [code: string]: SurveyQuestion };
}

export interface SurveyAnswer {
  code: string;
  text: string;
  value?: boolean;
  textNONADEGUATO?: string;
}

export interface RemoteSelling {
  agenteId: string;
  agenteEmail: string;
  product: string;
}

export interface QuotationRequest {
  visitorId?: string;
  touchPoint?: string;
  productCode: string;
  packageCode?: string;
  variables: {
    [name: string]: string | number | boolean | Array<string> | Object;
  };
  guarantees: { [name: string]: QuotationRequestGuarantee };
  policyHolder?: PolicyHolder;
  insured?: Array<Insurable>;
  productNode?: string;
  ageDescription?: string;
  survey?: Survey;
  referer?: string;
  remoteSelling?: RemoteSelling;
  quotationNumber?: string;
  asShop?: any;
  agent?: any;
  movement?: any;
}

export interface PolicyRequest extends QuotationRequest {
  transactionId: string;
  quotationNumber: string;
  policyHolder: PolicyHolder;
  productNode: string;
  insured: Array<PhysicalPeople>;
  survey: Survey;
  visitorId?: string;
  paymentDate: string;
  asShop: string;
}

export interface County {
  name: string;
  code?: string;
  zone?: string;
}

export interface QuoteDataRetrieve {
  sivi: Sivi;
  atrc: Atrc;
  sivi2: Sivi;
  atrc2: Atrc;
  motivoAssegnazione: string;
  missingInfo: {
    missingInfoCode: string;
    missingInfoMsg: string;
  };
}
export interface Sivi {
  type: "ApsaSiviResponse";
  esitoElaborazione: string;
  formatoIdentificativoVeicoloAlternativo: string;
  identificativoVeicoloAlternativo: string;
  tipoVeicoloAnia: string;
  tipoVeicoloMCTC: string;
  dataEmissione: string;
  dataImmatricolazione: string;
  tipoImmatricolazione: string;
  codiceCausale: string;
  codiceOmologazione: string;
  provinciaEmissioneCarta: string;
  provinciaResidenza: string;
  nazionalitaCostruttore: string;
  codiceCategoriaUso: string;
  codiceCarozzeria: string;
  cilindrata: string;
  cavalliFiscali: string;
  potenzaMassima: string;
  cilindri: string;
  marce: string;
  assi: string;
  giriMotore: string;
  velocitaMassima: string;
  tipoAlimentazione: string;
  lunghezza: string;
  larghezza: string;
  pesoVeicolo: string;
  pesoRimorchiabile: string;
  pesoSuRalla: string;
  numeroPosti: string;
  tara: string;
  codiceIstatProvinciaResidenza: string;
  codiceIstatComuneResidenza: string;
  comuneResidenza: string;
  codiceFiscaleIntestatario: string;
  classeInquinamento: string;
  provenienzaVeicolo: string;
  targaPrecedente: string;
  nomeCostruttore: string;
  marcaModelloVeicolo: string;
  denominazioneCommercialeVeicolo: string;
  dataAggiornamento: string;
  modelloMotore: string;
  numeroTempi: string;
  tipoPotenzaMassima: string;
  numeroPostiAnteriori: string;
  postiASedere: string;
  postiInPiedi: string;
  postiDiServizio: string;
  tipoCambio: string;
  rapportoTrasmissione: string;
  servosterzo: string;
  pneumatici: string;
  tipoFrenoServizio: string;
  approvazioneFrenoServizio: string;
  tipoFrenoSoccorso: string;
  numeroDecibel: string;
  giriNumeroDecibel: string;
  approvazioneSilenziatore: string;
  pesoPotenzialeComplessiva: string;
  pesoPotenzialeRimorchiabile: string;
  pesoPotenzialeMassima: string;
  rallaDa: string;
  rallaA: string;
  righeDescrittive: string;
  portata: string;
  portataUtile: string;
  portataPotenziale: string;
  limiteNumeroRichiami: string;
  limiteAnnoRichiamo: string;
  valore: string;
  indictoreEmissioneAzoto: string;
  indictoreEmissioneCarbonio: string;
  dataOmologazione: string;
  tipoValidita: string;
  idFuoristrada: string;
  modificabilitaOmologazione: string;
  emissioniBiossidoAzoto: string;
  tipoAbs: string;
  caratteristicheBase: string;
  limitatoreVelocita: string;
  emissioniCo2: string;
  tipoModello: string;
  varianteModello: string;
  annoImmatricolazione: string;
  dataInizioCircolazioneCiclomotore: string;
  tipologiaProprietario: string;
  filler1: string;
  sbalzo: string;
  rapportoPonte: string;
  interrasse1: string;
  interrasse2: string;
  interrasse3: string;
  applicazioneGancioTraino: string;
  statoEsteroCorpiSpeciali: string;
  codiceCicCiclomotore: string;
  filler2: string;
  dataAcquisto?: string;
}

export interface Atrc {
  type: "ApsaAtrcResponsePayload";
  esitoElaborazione: string;
  dataScadenzaContratto: string;
  tipoMovimento: string;
  causaleMovimento: string;
  codFiscaleContraente: string;
  codiceLegge: string;
  numeroPolizza: string;
  tariffa: string;
  numeroFranchigieNonCorrisposte: string;
  classeImpresaDa: string;
  classeImpresaA: string;
  classeCUDa: string;
  classeCuA: string;
  dataAggiornamento: string;
  cognomeContraente: string;
  nomeContraente: string;
  tipoVeicoloAnia: string;
  codiceImpresaCTP: string;
  codFiscaleAventeDiritto: string;
  cognomeAventeDiritto: string;
  nomeAventeDiritto: string;
  tipoAventeDiritto: string;
  naturaAventeDiritto: string;
  canale: string;
  tipologiaSinistri: string;
  Anno1: string;
  numeroSinistri1: string;
  Anno2: string;
  numeroSinistri2: string;
  Anno3: string;
  numeroSinistri3: string;
  Anno4: string;
  numeroSinistri4: string;
  Anno5: string;
  numeroSinistri5: string;
  Anno6: string;
  numeroSinistri6: string;
  Anno7: string;
  numeroSinistri7: string;
  Anno8: string;
  numeroSinistri8: string;
  Anno9: string;
  numeroSinistri9: string;
  Anno10: string;
  numeroSinistri10: string;
  Anno11: string;
  numeroSinistri11: string;
  filler1: string;
  timestampInserimento: string;
  timestampAggiornamento: string;
  dataRicezione: string;
  dataRicezioneAggiornamenti: string;
  tipoMovimentoInserimento: string;
  tipoCausaleInserimento: string;
  flagPolizzaGratuita: string;
  codiceIUR: string;
  tipoPolizza: string;
  filler2: string;
}

export interface ApsaQuoteSummary {
  numeroPreventivo: string;
  numeroPolizza: string;
  dataPreventivo: string;
  targa: string;
  modello: string;
  premio: string;
  giorniDiValidita: string;
  dataDecorrenza: string;
  tipoVeicolo: string;
  stato?: string;
}

export interface ApsaQuoteDetail {
  numeroPreventivo: string;
  dataDecorrenza: string;
  dataScadenza: string;
  targa: string;
  targa2: string;
  cognome2: string;
  nome2: string;
  telaio: string;
  codiceMarca: string;
  codiceModello: string;
  codiceAllestimento: string;
  marca: string;
  modello: string;
  allestimento: string;
  alimentazione: string;
  cvFiscali: string;
  cilindrata: string;
  dataImmatricolazione: string;
  valoreVeicolo: string;
  numeroPolizza: string;
  codiceConvenzione: string;
  convenzione: string;
  premio: string;
  provvigioni: string;
  motivoAssegnazione: string;
  sinistrosita: string;
  compagniaProvenienza: string;
  classeProvenienza: string;
  formaTariffaria: string;
  classeCuProvenienza: string;
  classeHiProvenienza: string;
  classeCuAssegnazione: string;
  classeHiAssegnazione: string;
  guidaEsclusiva: string;
  guidaSicura: string;
  antifurtoSatellitare: string;
  identificativo: string;
  provinciaIntestatatioAlPra: string;
  limitazioni: string;
  tipoTariffa: string;
  ivSconto: string;
  societa: string;
  tipoPagamento: string;
  codiceMassimaliO1: string;
  massimali: string;
  massimale1: string;
  massimale2: string;
  massimaleTot: string;
  capitaleAssicurato: string;
  nominativo: string;
  indirizzo: string;
  sesso: string;
  cFiscPivaContraente: string;
  cFiscPivaAssicurato: string;
  eta: string;
  giorniDiValidita: string;
  gancioTraino: string;
  senzaEsperienzaAss: string;
  cittaProvinvia: string;
  scontoConvenzioneRca: string;
  scontoConvFurtoIncendio: string;
  scontoFlessibilitaRca: string;
  scontoFlessibilitaFurtoIncendio: string;
  importoFlessiblitaFurtoIncendio: string;
  importoFlessibilitaRca: string;
  etaVeicolo: string;
  gruppoMarca: string;
  clienteGlobale: string;
  statoCliente: string;
  trattenuta: string;
  cap: string;
  kw: string;
  premioNettoRca: string;
  tasseRca: string;
  ssnRca: string;
  garanzia1: string;
  premioGar1: string;
  tasseGar1: string;
  scopertoGar1: string;
  minimoGar1: string;
  garanzia2: string;
  premioGar2: string;
  tasseGar2: string;
  scopertoGar2: string;
  minimoGar2: string;
  garanzia3: string;
  premioGar3: string;
  tasseGar3: string;
  scopertoGar3: string;
  minimoGar3: string;
  garanzia4: string;
  premioGar4: string;
  tasseGar4: string;
  scopertoGar4: string;
  minimoGar4: string;
  garanzia5: string;
  premioGar5: string;
  tasseGar5: string;
  scopertoGar5: string;
  minimoGar5: string;
  garanzia6: string;
  premioGar6: string;
  tasseGar6: string;
  scopertoGar6: string;
  minimoGar6: string;
  garanzia7: string;
  premioGar7: string;
  tasseGar7: string;
  scopertoGar7: string;
  minimoGar7: string;
  garanzia8: string;
  premioGar8: string;
  tasseGar8: string;
  scopertoGar8: string;
  minimoGar8: string;
  garanzia9: string;
  premioGar9: string;
  tasseGar9: string;
  scopertoGar9: string;
  minimoGar9: string;
  garanzia10: string;
  premioGar10: string;
  tasseGar10: string;
  scopertoGar10: string;
  minimoGar10: string;
  garanzia11: string;
  premioGar11: string;
  tasseGar11: string;
  scopertoGar11: string;
  minimoGar11: string;
  garanzia12: string;
  premioGar12: string;
  tasseGar12: string;
  scopertoGar12: string;
  minimoGar12: string;
  nominativoDipendente: string;
  cFiscDipendente: string;
  societaDipendente: string;
  matricolaDipendente: string;
  impresaProvenienza: string;
  premioPeriodo: string;
  codiceLeggeBersani: string;
  percProvvigioni: string;
  messG0e: string;
  bikeProgr: string;
  estensioni: string;
  rischicomp: string;
  tipoVeicolo: string;
  scontoTecnImponibile: string;
  scontoTecnPercentuale: string;
  scontoBBImponibile: string;
  scontoBBPercentuale: string;
  scontoBB2Imp: string;
  scontoBB2Perc: string;
  premioSinZero: string;
  percSinZero: string;
  premioSinUno: string;
  percSinUno: string;
  codPid: string;
  filler: string;
  status?: string;
  flagLeasing: string;
  dataAcquisto: string;
  codiceCarozzeria: string;
}

export interface InfoCar {
  settore?: string; // 1
  classe?: string; // 01
  uso?: string; // 01
  cavalli: string;
  codiceOmologazione: string;
  dataImmatricolazione: string;
  codiceMarca?: string;
  descrizioneMarca?: string;
  codiceModello?: string;
  descrizioneModello?: string;
  codiceAllestimento?: string;
  descrizioneAllestimento?: string;
  valoreUsato?: string;
  valoreNuovo?: string;
  valore?: string;
  codiceFiscaleIntestatario?: string;
  formatoTarga?: string;
  targa?: string;
  codiceFiscale2?: string;
  formatoTarga2?: string;
  targa2?: string;
  dataDecorrenza?: string;
  scadenzaContratto?: string;
  dataRiferimento?: string;
}

export interface CustomerData {
  name: string;
  surname: string;
  phone: string;
  email: string;
  fiscalCode: string;
  birthPlace: any;
  business: string;
  birthDate: string;
  privacyRead: string;
  brokerId: string;
  gender: string;
  birthPlaceAddress?: any;
  // customerType: string;
  // piva?: string
  // ragsoc?: string
}

export interface DeNPayload {
  context: {
    name: string;
    surname: string;
    phone: string;
    email: string;
    fiscalCode: string;
    birthDate: string;
  };
  uuid: string;
  agent: string
}

export interface UserB2C {
  user_id: number;
  connection_id: string;
  connection: string;
  login_token: string;
  userData: {
    address: string;
    district: string;
    city: string;
    email: string;
    fiscalCode: string;
    name: string;
    postalCode: string;
    surname: string;
    gender: string;
    placeOfBirth: string;
    dateOfBirth: Date;
    telephone: string;
    privacy_consent: PrivacyConsent;
    service_privacy: PrivacyConsent;
    marketing_privacy: PrivacyConsent;
    profiling_privacy: PrivacyConsent;
    identity_card_urls: string
    fiscal_code_urls: string
    documents: any
    origin: string
  };
}

export interface PrivacyConsent {
  company_code: string;
  consent: boolean;
  timestamp: Date;
}
export interface Preventivo {
  quotationNumber?: string;
  productName?: string;
  iconName?: string;
  dataDecorrenza?: Date;
  dataFineCopertura?: Date;
  stato?: StatoPreventivo;
  totalAmount?: string;
}

export interface Polizza {
  id?: string;
  prodotto?: string;
  dataInizioCopertura?: Date;
  dataFineCopertura: Date;
  stato?: StatoPreventivo;
  importo?: string;
}

export enum StatoPreventivo {
  /* ConveritoInPolizza = "Convertito in polizza",
  Acquistabile = "Acquistabile",
  DaCompletare = "Da completare", */
  ConveritoInPolizza = "CONVERTED",
  Acquistabile = "COMPLETED",
  ToCash = "TO_CASH",
  DaCompletare = "INCOMPLETE",
}

export enum PolicyStatus {
  Issued = "ISSUED",
  Accounted = "ACCOUNTED",
  ToCash = "TO_CASH",
  Payed = "PAYED",
  Exported = "EXPORTED",
  Aborted = "ABORTED",
  Canceled = "CANCELED",
  CanceledByCompany = 'CANCELED_BY_COMPANY',
  Replaced = "REPLACED"
}


export interface CustomersTables {
  id: string;
  title: string;
  columns: string[];
  data: Preventivo;
}

export interface Variables {
  INSURED_GROUPS: any;
  ASSICURATI: number;
  DECORRENZA: string;
  FINE_COPERTURA: string;
  DURATA: number;
}
/*
export interface EASYSKI9000SLALOMASSITENZA {
}

export interface EASYSKI9000SLALOMSPESECURA {
}

export interface EASYSKI9000SLALOMRC {
}

export interface Guarantees {
  EASYSKI-9000-SLALOM-ASSITENZA: EASYSKI9000SLALOMASSITENZA;
  EASYSKI-9000-SLALOM-SPESE-CURA: EASYSKI9000SLALOMSPESECURA;
  EASYSKI-9000-SLALOM-RC: EASYSKI9000SLALOMRC;
} */

/* export interface Birth {
  date: string;
  nation: string;
  place: string;
  district: string;
}

export interface Insured {
  idx: number;
  surname: string;
  firstName: string;
  fiscalCode: string;
  sex: string;
  birth: Birth;
  italianResidential: boolean;
} */

/* export interface AsShop {
} */

/* export interface Birth2 {
  date: string;
  nation: string;
  place: string;
  district: string;
} */

/* export interface ResidentialAddress {
  city: string;
  district: string;
  zipCode: string;
  addressNumber: string;
  address: string;
  nation: string;
} */

/* export interface PolicyHolder {
  idx: number;
  firstName: string;
  surname: string;
  fiscalCode: string;
  email: string;
  phone: string;
  birth: Birth2;
  italianResidential: boolean;
  sex: string;
  flyerRead: boolean;
  privacyRead: boolean;
  checkPrivacy: boolean;
  checkMarketingPrivacy: boolean;
  checkProfilePrivacy: boolean;
  residentialAddress: ResidentialAddress;
} */

/* export interface Survey {
} */

export interface Request {
  referer: string;
  visitorId: string;
  productCode: string;
  packageCode: string;
  productNode: string;
  variables: Variables;
  guarantees: Guarantee[];
  insured: Insurable;
  asShop: any;
  policyHolder: PolicyHolder;
  survey: Survey;
  touchPoint: string;
}

export interface Quote {
  guarantees: any[];
  price: number;
  grossPrice: number;
  taxes: number;
  quotationNumber: string;
}

export interface Quote4Customer {
  _id: string;
  customerId: string;
  status: string;
  request: Request;
  quote: Quote;
  quotationNumber: string;
  touchPoint: string;
  __STATE__: string;
  creatorId: string;
  updaterId: string;
  updatedAt: Date;
  createdAt: Date;
}

export interface Customer {
  _id: string,
  name: string,
  surname: string,
  phone: string,
  email: string,
  fiscalCode: string
}

export interface Preselling {
  _id: string,
  customerId: string,
  mailId?: string,
  createdAt: string,
  product: string,
  customerName: string,
  customerSurname: string,
  customerFiscalCode: string,
  customerPhone: string,
  customerMail: string,
  numeroProposta: string,
  withProposal: boolean
  documents?: any[]
  mode: boolean
  jobId?: string
  presellingFileName?: string
}

export interface InformativeSet {
  code: string
  branch: string
  description: string
  id: string
}
