import { NgModule } from '@angular/core';
import { ProductListMockService, ProductListService } from './product-list.mock-service';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    { provide: ProductListService, useClass: process.env.ENV === 'mock' ? ProductListMockService : ProductListService },
  ]
})
export class ProductListServiceModule {
}
