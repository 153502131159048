import moment = require("moment");
import * as _ from "lodash";

export const dateFormat = "YYYY,M,D";
export const inputDateFormat = "DD.MM.YYYY";
export const isoFormatDate = "YYYY-MM-DD";
export const itaFormatDate = "DD/MM/YYYY";

export const MIN_AGE_GUIDA_ESPERTA = 26;

export function dateFromTo(value: string, from: string, to: string): string {
  const theDate = moment(value, from);

  return theDate.isValid() ? theDate.format(to) : undefined;
}

export function dateFormInputToIso(value: string): string {
  return dateFromTo(value, inputDateFormat, isoFormatDate);
}

export function dateFormInputToDefault(value: string): string {
  return dateFromTo(value, inputDateFormat, isoFormatDate);
}

export function dateFormIsoToInput(value: string): string {
  return dateFromTo(value, isoFormatDate, inputDateFormat);
}
export function dateFormItaToInput(value: string): string {
  return dateFromTo(value, itaFormatDate, inputDateFormat);
}
// calcLuhnModN()
//
// Helper function that calculates and returns the Luhn digit from a serial number 'serialNum'.
// 'includesCheckDigit' is Boolean true if the serial number passed includes the check digit
// at the end.  A value of false indicates the check digit is not included.  It can handle both
// decimal and hexadecimal values by passing a radix value of 10 or 16 respectively.  A radix
// value of anything between 1 and 16 would be supported but only 10 and 16 have been verified.
//
//
export function calcLuhnModN(serialNum, includesCheckDigit, radix) {
  var sum = 0;
  var parity = serialNum.length % 2;
  for (var i = 0; i < serialNum.length - (includesCheckDigit ? 1 : 0); i++) {
    let codePoint = parseInt(serialNum[i], radix);
    let doubleDigit =
      ((i + (includesCheckDigit ? 0 : 1)) % 2 === parity ? 2 : 1) * codePoint;
    let sumOfDigits = Math.floor(doubleDigit / radix) + (doubleDigit % radix);
    sum += sumOfDigits;
  }
  return ((radix - (sum % radix)) % radix).toString(radix).toUpperCase();
}

export enum GoogleAddresComponentsType {
  LOCALITY = "locality",
  POLITICAL = "political",
  ADMINISTRATIVE_AREA_LEVEL_1 = "administrative_area_level_1",
  ADMINISTRATIVE_AREA_LEVEL_2 = "administrative_area_level_2",
  ADMINISTRATIVE_AREA_LEVEL_3 = "administrative_area_level_3",
  COUNTRY = "country",
  POSTAL_CODE = "postal_code",
}

export const getAddressComponent = (
  addressComponent,
  type: GoogleAddresComponentsType
) => {
  if (_.isArray(addressComponent)) {
    let ret = addressComponent.filter(function (entry) {
      return entry.types.indexOf(type) >= 0;
    });
    if (ret.length >= 1) return ret[0];
  }
  return { short_name: "" };
};

export const testNumber = (phoneNumber: string) => {
  return testRegexp(phoneNumber, /^[0-9]+$/);
};

export const testFiscalCode = (fiscalCode: string) => {
  return testRegexp(
    fiscalCode.toUpperCase(),
    "^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$"
  );
};

export const testEmail = (email: string) => {
  return testRegexp(email, /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/);
};

function testRegexp(test, regexp) {
  const r = new RegExp(regexp);
  return r.test(test);
}

export enum PresellingUploadableFiles {
  ALLEGATO_3 = "Allegato 3",
  ALLEGATO_4 = "Allegato 4",
  ALLEGATO_4_TER = "Allegato 4-ter",
  DEMANDS_NEEDS = "Demands and Needs",
  COERENZA = "Questionario di coerenza precompilato",
  PROPOSTA_POLIZZA = "Proposta di polizza",
  PRIVACY = "Privacy",
}

export enum PurchaseQuoteUploadableFiles {
  CERTCIRC = 'Certificato libretto circolazione',
  PASSPROP = 'Documento passaggio proprietà provvisorio',
  POLPREC = 'Documento polizza precedente',
  CERTANTF = 'Certificato antifurto installato',
  POLSCAD = 'Documento polizza scaduta',
  CERTLEAS = 'Contratto di leasing',
  OTHER = 'Altro documento (opzionale)',
}

export const DeviceBrandList = [
  { code: "1", name: "Acer" },
  { code: "2", name: "Alcatel" },
  { code: "3", name: "Apple" },
  { code: "4", name: "Asus" },
  { code: "5", name: "Blackberry" },
  { code: "6", name: "Google" },
  { code: "7", name: "HTC" },
  { code: "8", name: "Huawei" },
  { code: "9", name: "LG" },
  { code: "10", name: "Motorola" },
  { code: "11", name: "Nokia" },
  { code: "12", name: "One Plus" },
  { code: "13", name: "Razer" },
  { code: "14", name: "Samsung" },
  { code: "15", name: "Sony" },
  { code: "16", name: "Xiaomi" },
  { code: "17", name: "Altro" }
];

export const DeviceTypeList = [
  { name: "Smartphone/Cellulare", code: "smartphone" },
  { name: "Tablet", code: "tablet" },
  { name: "Laptop", code: "laptop" },
];
