import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { UserService, UserType } from "./user.service";
import { NgSellyConfig } from "ng-selly.config";
import { FEATURES } from "../../config/ng-selly-base.config";
import { verify } from "jsonwebtoken";
import { QuotationService } from "../../selly-app/quotation.service";
import moment = require("moment");

@Injectable()
export class CheckBeforeLogin {
	constructor(private router: Router, private config: NgSellyConfig) {}

	public canLogin(): boolean {
		const agent = window.navigator.userAgent.toLowerCase();
		let dayNumber = moment().isoWeekday();
		let now = moment().format("HH:mm:ss");
		/*   if (this.config.closingDay.includes(dayNumber)) {
        this.router.navigateByUrl(`/not-operational(header:header//footer:footer)?reason=maintenance`);
        return false;
      } else { */
		if (agent.indexOf("trident") > -1) {
			//console.log('IE');
			this.router.navigateByUrl(
				`/not-operational(header:header//footer:helvetiaitalia)?reason=agent`
			);
			return false;
		}
		return true;
		/*  } */
	}
}

@Injectable()
export class ThankYouPageGuard implements CanActivate {
	constructor(
		private router: Router,
		private userService: UserService,
		private config: NgSellyConfig,
		private quotationService: QuotationService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		let productCode =
			this.quotationService.currentRequest && this.quotationService.currentRequest.productCode;
		let url = state.url;

		if (url.includes("header:header")) {
			return true;
		} else {
			if (productCode === "AFFINITY-AUTO") {
				this.router.navigateByUrl(
					`/thankyou(header:header;header=APSA_POST_ACQUISTO//footer:helvetiaitalia)`
				);
				return false;
			}
			return true;
		}
	}
}

@Injectable()
export class AgentsGuard implements CanActivate {
	constructor(
		private router: Router,
		private userService: UserService,
		private config: NgSellyConfig
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		/* this.router.navigateByUrl(`/out-of-order(header:header//footer:helvetiaitalia)`);
    return false; */

		const data = this.userService.isAuthenticated();
		if (data.logged && (data.type === UserType.AGENT || data.type === UserType.DIREZIONE))
			return true;

		sessionStorage.clear();
		sessionStorage.setItem("originUrl", state.url);
		window.location.href = this.config.agentLoginUrl;
		this.router.navigateByUrl("/auth/login");
		return false;
	}
}

@Injectable()
export class UserGuard implements CanActivate {
	constructor(
		private router: Router,
		private userService: UserService,
		private config: NgSellyConfig
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		/* this.router.navigateByUrl(`/out-of-order(header:header//footer:helvetiaitalia)`);
    return false; */

		const data = this.userService.isAuthenticated();
		console.log(data);
		console.log("UserGuard");
		if (
			data.logged &&
			[UserType.CUSTOMER, UserType.ASSHOP, UserType.CUSTOMER_AS_AGENT, UserType.AFFINITY].includes(
				data.type
			)
		) {
			let canAccess = this.processPreSelling(route.queryParams["token"]);
			return canAccess;
		}

		let referrer = sessionStorage.getItem("referrer");
		sessionStorage.clear();
		sessionStorage.setItem("originUrl", state.url);
		sessionStorage.setItem("referrer", referrer);
		window.location.href = this.config.customerLoginUrl;
		this.router.navigateByUrl("/auth/login");
		return false;
	}

	processPreSelling(token: string): boolean {
		if (!process.env.PRE_SELLING || !token) return true;
		try {
			let data: any = verify(token, this.config.publicKey, { algorithms: ["RS256"] });
			this.userService.userState.productNode = data.agente.id;
			this.userService.userState.tokenInfo = data;
			this.userService.save();
		} catch (e) {
			this.router.navigateByUrl("/auth/security");
			return false;
		}
		return true;
	}
}

@Injectable()
export class UserForRemoteSellingGuard implements CanActivate {
	constructor(
		private router: Router,
		private userService: UserService,
		private config: NgSellyConfig
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		/* this.router.navigateByUrl(`/out-of-order(header:header//footer:helvetiaitalia)`);
    return false; */

		const data = this.userService.isAuthenticated();
		if (data.logged && [UserType.CUSTOMER_AS_AGENT].includes(data.type)) {
			return true;
		}
		let token = route.queryParams.token;
		sessionStorage.clear();
		sessionStorage.setItem("originUrl", state.url);
		window.location.href = this.config.remoteSellingLoginUrl + `?token=${token}`;
		this.router.navigateByUrl("/auth/login");
		return false;
	}
}

@Injectable()
export class LiquidatoreGuard implements CanActivate {
	constructor(
		private router: Router,
		private userService: UserService,
		private config: NgSellyConfig
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		/* this.router.navigateByUrl(`/out-of-order(header:header//footer:helvetiaitalia)`);
    return false; */

		const data = this.userService.isAuthenticated();
		if (data.logged && data.type === UserType.LIQUIDATORE) return true;

		sessionStorage.clear();
		sessionStorage.setItem("originUrl", state.url);
		window.location.href = this.config.liquidatoreLoginUrl;
		this.router.navigateByUrl("/auth/login");
		return false;
	}
}

@Injectable()
export class AsShopGuard implements CanActivate {
	constructor(
		private router: Router,
		private userService: UserService,
		private config: NgSellyConfig
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		/*  this.router.navigateByUrl(`/out-of-order(header:header//footer:helvetiaitalia)`);
    return false; */

		if (!this.config.features.includes(FEATURES.SHOPS)) return false;

		const data = this.userService.isAuthenticated();
		if (data.logged && data.type === UserType.ASSHOP) return true;

		sessionStorage.clear();
		sessionStorage.setItem("originUrl", state.url);
		window.location.href = this.config.shopLoginUrl + "/" + route.params.shopCode;
		this.router.navigateByUrl(`/auth/login`);
		return false;
	}
}

@Injectable()
export class AffinityGuard implements CanActivate {
	constructor(
		private router: Router,
		private userService: UserService,
		private config: NgSellyConfig,
		private checkBeforeLogin: CheckBeforeLogin
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		let canLogin = this.checkBeforeLogin.canLogin();
		console.log(canLogin, process.env.AFFINITY_DISABLED);

		/* this.router.navigateByUrl(`/out-of-order(header:header//footer:helvetiaitalia)`);
    return false; */

		if (process.env.AFFINITY_DISABLED) {
			console.log("AFFINITY_DISABLED");
			this.router.navigateByUrl(`/out-of-order(header:header//footer:helvetiaitalia)`);
			return false;
		} else {
			if (canLogin) {
				if (!this.config.features.includes(FEATURES.AFFINITY)) {
					console.error("Missing feature Affinity", this.config.features);
					return false;
				}

				const data = this.userService.isAuthenticated();
				if (data.logged && data.type === UserType.AFFINITY) return true;

				sessionStorage.clear();
				sessionStorage.setItem("originUrl", state.url);

				/* Old login page: https://ng-selly-server-devel.helvetia.it/auth/4Affinity */
				/*  window.location.href = this.config.affinityLoginUrl;  */

				const originName = "selly-4affinity";
				window.location.href = `${this.config.signUp}accedi/?origin=${originName}`;
				/* this.router.navigateByUrl(`/auth/login`); */
				return false;
			}
		}
	}
}
