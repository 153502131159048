export class NgSellyBaseConfig {
  apiServer: string = null;
  btcProductNode: string = null;
  googleApiKey: string = null;
  publicKey: string = null;
  agentLoginUrl: string = null;
  customerLoginUrl: string = null;
  liquidatoreLoginUrl: string = null;
  shopLoginUrl: string = null;
  remoteSellingLoginUrl: string = null;
  affinityLoginUrl: string = null;
  features: string[] = [];
  sistemStart: string = null;
  sistemEnd: string = null;
  nPlLink?: string;
  closingDay: number[];
  reCaptchaSecret: string = null;
  apiServerK8s: string = null;
  apiServerK8sV1: string = null;
  /* btcLogin: string = null; */
  sellyk8: string = null;
  signUp: string = null;
  adobeUrl: string = null;
  sellyApik8: string = null;
  consentsUrl: string = null;
  consentsCompanyId: string = null;
  consentsCompanyTCMId: string = null;
  consentsCollectionToken: string = null;

  consentsCollectionTCMToken: string = null;

  constructor() {
    this.btcProductNode = "9000";
    this.googleApiKey = "AIzaSyCcq1YVwHtOCCcJpweG-nRw4nmzqWHZ3mE";
    this.publicKey = `-----BEGIN PUBLIC KEY-----
MIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgG4WgDYvFmG01LY6yOvC44WRg6m/
uhRR+tfr2rVF7sS1+CXVZ2Ye3nFiF8NiWLTWgFt7UqY3EcHk80OeKjJFI3XEnUB2
KlBTdzsYojoKof9EXXYbaNMrIVGmXWaukis9WaDrDGRJFSuMpQx5HmNAFGzWtw6I
GXyIkaxEE/rBk/NLAgMBAAE=
-----END PUBLIC KEY-----`;
    this.reCaptchaSecret = "6Ldzx9kZAAAAAPlrh7vQRQOrRhDBkNGuGgHZhTe7";

    if (process.env.SHOPS) this.features.push(FEATURES.SHOPS);
    if (process.env.REMOTE_SELLING) this.features.push(FEATURES.REMOTE_SELLING);
    if (process.env.PRE_SELLING) this.features.push(FEATURES.PRE_SELLING);
    if (process.env.AFFINITY) this.features.push(FEATURES.AFFINITY);
    if (process.env.ENABLE_DN) this.features.push(FEATURES.ENABLE_DN);
  }
}

export enum FEATURES {
  // @ts-ignore
  REMOTE_SELLING = "remote_selling",
  // @ts-ignore
  PRE_SELLING = "pre_selling",
  // @ts-ignore
  SHOPS = "shops",
  // @ts-ignore
  AFFINITY = "affinity",
  // @ts-ignore
  ENABLE_DN = "enable_dn",
}
