import { NgModule } from '@angular/core';
import { ProductListComponent } from './product-list.component';
import { ProductListServiceModule } from '../../remote-service-module/product-list/product-list.service.module';
import { CommonModule } from '@angular/common';
import {BigBoxModule} from "../big-box/big-box-module";

@NgModule({
  imports: [
    CommonModule,
    ProductListServiceModule,
    BigBoxModule
  ],
  declarations: [
    ProductListComponent,
  ],
  exports: [
    ProductListComponent
  ]
})
export class ProductListModule {
}
