import { NgModule } from '@angular/core';
import { StickyFooterComponent } from './sticky-footer.component';
import { StickyFooterLinkComponent } from './sticky-footer-link.component';
import { StickyFooterTextComponent } from './sticky-footer-text.component';

@NgModule({
  declarations: [
    StickyFooterComponent,
    StickyFooterLinkComponent,
    StickyFooterTextComponent,
  ],
  exports: [
    StickyFooterComponent,
    StickyFooterLinkComponent,
    StickyFooterTextComponent,
  ]
})
export class StickyFooterModule {
}
