import { Component } from '@angular/core';

@Component({
  selector: 'fw-not-found-page',
  template: require('./not-found-page.html'),
  styles: [require('./not-found-page.scss')],
})
export class NotFoundPageComponent {
  constructor() {
  }
}
