import { Component } from '@angular/core';

@Component({
  selector: 'fw-sticky-footer',
  template: require('./sticky-footer.html'),
  styles: [require('./sticky-footer.scss')],
})
export class StickyFooterComponent {
  constructor() {
  }

}
