import { NgModule } from '@angular/core';
import { OsCommonModule } from '@helvetia/ng-oscommon/index';
import { UxBaseModule } from '@helvetia/ng-ux-base/index';
import { SaveForLateComponent } from './save-for-late.component';
import { SaveForLateService } from './save-for-late.service';

@NgModule({
  imports: [
    UxBaseModule.forRoot(),
    OsCommonModule.forRoot(),
  ],
  declarations: [
    SaveForLateComponent
  ],
  exports: [
    SaveForLateComponent
  ],
  providers: [
    SaveForLateService
  ],
  entryComponents: [
    SaveForLateComponent
  ]
})
export class SaveForLateModule {

}
