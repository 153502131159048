import { Component } from '@angular/core';
import { QuotationService } from 'src/selly-app/quotation.service';
import { UserService, UserType } from 'src/selly-shared-module/auth/user.service';


@Component({
  selector: 'fw-footer-hi',
  template: require('./helvetiaitalia.html'),
  styles: [require('./helvetiaitalia.scss')],
})
export class HelvetiaItaliaComponent {
  yearCopyright = Date.now();
  constructor(private userService: UserService, private quotationService: QuotationService){

  }

  isAgent() {
    return this.userService.isAuthenticated().type === UserType.AGENT;
  }
}
