import { NgModule } from '@angular/core';
import { OsCommonModule } from '@helvetia/ng-oscommon/index';
import { UxBaseModule } from '@helvetia/ng-ux-base/index';
import { BigBoxComponent } from './big-box.component';

@NgModule({
  imports: [
    UxBaseModule.forRoot(),
    OsCommonModule.forRoot(),
  ],
  declarations: [ BigBoxComponent ],
  exports: [ BigBoxComponent ]
})
export class BigBoxModule {
}
