import { Component, ViewChild } from '@angular/core';
import { interval } from 'rxjs/index';
import { SavedForLate, SaveForLateService } from './save-for-late.service';
import { QuotationService } from '../../selly-app/quotation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'fw-save-for-late',
  template: require('./save-for-late.html')
})
export class SaveForLateComponent {

  @ViewChild('overlaySaveForLateSaved', {static: true})
  private overlaySaveForLateSaved: any;

  @ViewChild('overlaySaveForLateUseCurrent', {static: true})
  private overlaySaveForLateUseCurrent: any;

  constructor(
    private router: Router,
    private quotationService: QuotationService) {}

  showOverlaySaveForLateSaved() {
    let s = interval(100).subscribe(() => {
      if (this.overlaySaveForLateSaved) {
        this.overlaySaveForLateSaved.open();
        s.unsubscribe();
      }
    });
  }

  showOverlaySaveForLateUseCurrent() {
    let s = interval(100).subscribe(() => {
      if (this.overlaySaveForLateUseCurrent) {
        this.overlaySaveForLateUseCurrent.open();
        s.unsubscribe();
      }
    });
  }

  recover() {
    let _saveForLate: string = localStorage.getItem('saveForLate');
    if (_saveForLate === null) return;

    try {
      const saveForLate: SavedForLate = JSON.parse(_saveForLate);
      sessionStorage.setItem('wizardStatus', saveForLate.steps);
      this.quotationService.init(saveForLate.currentRequest);
      localStorage.removeItem('saveForLate');
      window.location.href = saveForLate.page;

    } catch (e) {
      console.error('Error retrieving data from storage');
    }
  }

}
