import { Component, OnDestroy, OnInit, ViewContainerRef, ViewEncapsulation, ViewChild, AfterContentInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs/index';
import { SpinnerConfig, SpinnerController, SpinnerStatus } from '../custom-component-module/spinner-controller/spinner.controller';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { UserService } from '../selly-shared-module/auth/user.service';
import { NgSellyConfig } from 'ng-selly.config';
import { SaveForLateService } from '../custom-component-module/save-for-late/save-for-late.service';
import { FEATURES } from '../config/ng-selly-base.config';
import { ScreenOverlayNoBarComponent } from '../custom-component-module/fw-screen-overlay/screen-overlay-nobar.component';
import { InfoBoxController, InfoBoxConfig } from '../custom-component-module/infoBox-controller/infobox.controller';
import { ErrorService } from './utils/error.service';
import { takeUntil } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/custom-component-module/errors-handler/error-handler.service';
import { AuthenticationService } from 'src/selly-web-module/authentication.service';

@Component({
  selector: 'selly-app',
  template: require('./selly-app.html'),
  styles: [require('./selly-app.scss')],
  encapsulation: ViewEncapsulation.None
})
export class SellyAppComponent implements OnDestroy, OnInit, AfterContentInit {

  showSpinner: boolean = false;
  textSpinner: string;
  features = FEATURES;

  private footerSubscription: Subscription;
  private currentUrlRoot: string;


  @ViewChild('soInfoBox', {static: true})
  infobox: any;

  @ViewChild('errorInfo', {static: true})
  private errorInfo: any;

  infoBoxText:string;
  infoboxTitle:string;
  infoBoxIcon:string;
  errorCode: {
    icon?:string;
    title?:string;
    html?:string;
    errorCode?: string
  };
  private ErrorUnsubscribe = new Subject();

  constructor(private router: Router,
              private userService: UserService,
              private authService: AuthenticationService,
              private saveForLate: SaveForLateService,
              viewContainerRef: ViewContainerRef,
              activatedRoute: ActivatedRoute,
              spinnerController: SpinnerController,
              infoBoxController: InfoBoxController,
              public config: NgSellyConfig,
              private errorService: ErrorService,
              private errorHandlerService: ErrorHandlerService ) {


    saveForLate.setRootViewContainerRef(viewContainerRef);
    setTimeout(() => {
      saveForLate.checkForSavedData();
    }, 1000);


    activatedRoute.data.subscribe((data: Data) => {
      this.currentUrlRoot = data.urlRoot;
    });
    let spinnerOpened = 0;
    spinnerController.subscribe().subscribe((spinnerStatus: SpinnerConfig) => {
      if (spinnerStatus.status === SpinnerStatus.OPEN) {
        console.log('if', spinnerOpened)
        spinnerOpened++;
        this.showSpinner = true;
        this.textSpinner = spinnerStatus.text;
      } else {
        spinnerOpened--;
        console.log('else', spinnerOpened)
        if (spinnerOpened === 0){
          this.showSpinner = false;
        }
      }
    });
    infoBoxController.change.subscribe((infoBoxConfig: InfoBoxConfig)=>{

      this.errorCode = infoBoxConfig.errorCode;
      /* if(this.errorCode.errorCode !== 'SESSION'){ */
        if( this.errorCode && this.errorCode.icon) {
          this.infoBoxIcon = infoBoxConfig.errorCode.icon;
          this.infoboxTitle = infoBoxConfig.errorCode.title;
          this.infoBoxText = infoBoxConfig.errorCode.html;
          this.infobox.title = '';
        } else if (this.errorCode && this.errorCode.icon === null) {
          // if (infoBoxConfig.title) this.infobox.title = infoBoxConfig.title;
          // this.infoBoxText = infoBoxConfig.html;
          this.infobox.title = infoBoxConfig.errorCode.title;
          this.infoBoxText = infoBoxConfig.errorCode.html;
        } else if (!this.errorCode) {
          this.infobox.title = infoBoxConfig.title;
          this.infoBoxText = infoBoxConfig.html;
        }

        //this.infoBoxIcon = infoBoxConfig.icon;
        this.infobox.open();
     /*  } */

    });
  }


  navigate(section: string) {
    this.router.navigate([
      'selly-4agents',
      section]);

  }

  ngAfterContentInit(){
    this.initializeErrors();
  }

  ngOnInit() {

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  reload(){
    window.SellyAppModule.reloadOTBanner()
  }

  ngOnDestroy(): void {
    this.footerSubscription.unsubscribe();
    this.ErrorUnsubscribe.next();
    this.ErrorUnsubscribe.complete();
  }
  goHome(){
    const userType = this.authService.isAuthenticated().type
    console.log('userType', userType)

    this.infobox.close()
    if(userType == 'affinity') {
      sessionStorage.clear();
      window.location.href = this.config.affinityLoginUrl + '/logout';
      //this.router.navigate(['selly-4affinity']);
    } else if(userType == 'agent') {
      this.userService.logout();
      window.location.href = 'https://portale.helvetia.it/'
      //this.router.navigate(['selly-4agents']);
    } else {
      this.authService.logout()

    }
  }

  initializeErrors()
    {
        this
            .errorService
            .getErrors()
            .pipe(takeUntil(this.ErrorUnsubscribe))
            .subscribe((errors) =>
            {
              this.errorCode = this.errorHandlerService.getError(errors.error.errorCode)
              console.log('errors', this.errorCode.title)

              if( this.errorCode) {
                this.infoBoxIcon = this.errorCode.icon;
                this.infoboxTitle = this.errorCode.title;
                this.infoBoxText = this.errorCode.html;
              } else if (!this.errorCode) {
                this.errorCode.title = 'Attenzione';
                this.errorCode.html = 'errore generico';
              }
              //chiudo gli spinner se rimangono aperti da una chiamata
              this.showSpinner = false
              this.infobox.open();
            });
    }

}


