import { UserStateLoggedIn } from './../selly-shared-module/auth/user.service';
import { Address } from './../selly-shared-module/models';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { take } from "rxjs/operators";
import * as _ from "lodash";
import { UserB2C } from "src/selly-shared-module/models";
import { UserState } from "src/selly-shared-module/auth/user.service";
import { NgSellyConfig } from "ng-selly.config";
import {
  inputDateFormat,
  isoFormatDate,
} from "src/custom-component-module/utils/costants";
import moment = require("moment");
import { SpinnerController } from 'src/custom-component-module/spinner-controller/spinner.controller';
import { Router } from '@angular/router';
import { BehaviorSubject,iif, Observable } from 'rxjs'

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  public currentUser: Observable<any>;
  // @ts-ignore
  user: User;
  private currentUserSubject: BehaviorSubject<any>;
  snack: any;

  constructor(private http: HttpClient,
    private config: NgSellyConfig,
    private spinnerController: SpinnerController,
    private router: Router
  ) {
    console.log("AuthenticationService");
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(<string>sessionStorage.getItem("userState"))
    );
    this.currentUser = this.currentUserSubject.asObservable();

    const user = sessionStorage.getItem("userState");
    if (!_.isNil(user)) {
      this.user = JSON.parse(user);
    }
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  setUser(user: any) {
    this.user = user;
    // this.user.loginToken = user.login_token;
    sessionStorage.setItem("userState", JSON.stringify(user));
    this.changeUser(this.user);
  }

  changeUser(user: UserState) {
    this.currentUserSubject.next(user);
  }

  //  funzione per generare userState al signup
  getUserB2C(dataUser: UserB2C) {
    let userData = {}
    let user = dataUser.userData;
    // costruisco userData con tutte le proprietà di dataUser
    Object.entries(user).forEach(([key, value]) => {
      if(key === 'telephone'){
        userData['phone_number'] = value
      } else if(!['cap', 'mobile_phone_number', 'business_name'].includes(key)){
        userData[key] = value
      }
      userData['userId'] = dataUser.user_id
    })
    let userState: UserState;
    userState = JSON.parse(sessionStorage.getItem("userState"));


    userState.loggedInAs = userData as UserStateLoggedIn;
    userState.loginToken = dataUser.login_token;
    return userState;
  }

  public isAuthenticated(): any {
    this.user = JSON.parse(<string>sessionStorage.getItem("userState"));
    // @ts-ignore
    return this.user;
  }

  logout() {
    this.spinnerController.show();
    if (this.user) {
      this.user.loggedInAs = null;
      this.user.loginToken = null;
    }

    this.user = null
    sessionStorage.setItem("originUrl", '/selly-web/catalog(header:header//footer:footer)');
    sessionStorage.removeItem('userState')
    setTimeout(() => this.anonymousLogin(), 500);

  }

  // torno alla pagina della lista prodotti
  anonymousLogin() {
    console.log('anonymousLogin')
    this.spinnerController.close();
    let referrer = sessionStorage.getItem('referrer');
    sessionStorage.setItem('referrer', referrer);
    window.location.href = this.config.customerLoginUrl;
    this.router.navigateByUrl('/auth/login');
  }


}

