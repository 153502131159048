// export enum ERROR_CODE_MESSAGE_MAP {
//     SOSTITUZIONE_VEICOLO_NO_FULL_MATCH = "ATTENZIONE: l'intestatario del veicolo in sostituzione non coincide con i tuoi dati anagrafici - Contattare Apsa all'indirizzo operativoapsa@agenziapadana.it",
//     SOSTITUZIONE_VEICOLO_ATRC2_NO_MATCH = "ATTENZIONE: l'avente diritto dell'attestato di rischio non corrisponde - Contattare Apsa all'indirizzo operativoapsa@agenziapadana.it",
//     SOSTITUZIONE_VEICOLO_NO_ATRC2 = "ATTENZIONE: non è stato trovato l'attestato di rischio in Banca Dati - Contattare Apsa all'indirizzo operativoapsa@agenziapadana.it",
//     LEGGE_BERSANI_ATRC2_NO_MATCH = "ATTENZIONE: Legge Bersani non applicabile (differente codice fiscale o provincia di immatricolazione) - Contattare Apsa all'indirizzo operativoapsa@agenziapadana.it",
//     LEGGE_BERSANI_NO_ATRC2 = "ATTENZIONE: Legge Bersani non applicabile (attestato di rischio non trovato in Banca Dati) - Contattare Apsa all'indirizzo operativoapsa@agenziapadana.it",
//     LEGGE_BERSANI_PERSONA_GIURIDICA = "ATTENZIONE: Legge Bersani non applicabile (veicolo di proprieta' di una Persona Giuridica) - Contattare Apsa all'indirizzo operativoapsa@agenziapadana.it",
//     BONUS_FAMIGLIA_ATRC2_NO_MATCH = "ATTENZIONE: Bonus Famiglia non applicabile (differente codice fiscale o provincia di immatricolazione) - Contattare Apsa all'indirizzo operativoapsa@agenziapadana.it",
//     BONUS_FAMIGLIA_NO_ATRC2 = "ATTENZIONE: Bonus Famiglia non applicabile (attestato di rischio non trovato in Banca Dati) - Contattare Apsa all'indirizzo operativoapsa@agenziapadana.it",
//     BONUS_FAMIGLIA_PERSONA_GIURIDICA = "ATTENZIONE: Bonus Famiglia non applicabile (veicolo di proprieta' di una Persona Giuridica) - Contattare Apsa all'indirizzo operativoapsa@agenziapadana.it",
//     BONUS_FAMIGLIA_NO_VALID = "Bonus Famiglia non applicabile per la targa indicata",
//     NO_CONV_VALID = "ATTENZIONE: Non resulta attiva nessuna convenzione - Contattare Apsa all'indirizzo operativoapsa@agenziapadana.it",
//     ALREADY_INSURED = "ATTENZIONE: Veicolo con polizza RCA attiva presente in Banca Dati  - Contattare Apsa all'indirizzo operativoapsa@agenziapadana.it",
//     DEFAULT = "Attenzione si è verificato un errore non previsto"
// }

export class ERROR_CODE_MESSAGE_MAP {

  static readonly SOSTITUZIONE_VEICOLO_NO_FULL_MATCH =
    new ERROR_CODE_MESSAGE_MAP(
      'generic',
      '<span class="uxa-text-bold">L\'intestatario del veicolo in sostituzione non coincide con i tuoi dati anagrafici</span>',
      '<span class="uxa-text-bold">Ti consigliamo di contattare Apsa</span> all\'indirizzo operativoapsa@agenziapadana.it'
    );
  static readonly SOSTITUZIONE_VEICOLO_ATRC2_NO_MATCH =
    new ERROR_CODE_MESSAGE_MAP(
      'attestato',
      '<span class="uxa-text-bold">L\'avente diritto dell\'attestato di rischio non corrisponde</span>',
      '<span class="uxa-text-bold">Ti consigliamo di contattare Apsa</span> all\'indirizzo operativoapsa@agenziapadana.it'
    );
  static readonly SOSTITUZIONE_VEICOLO_NO_ATRC2 =
    new ERROR_CODE_MESSAGE_MAP(
      "find-error",
      '<span class="uxa-text-bold">Non stiamo riuscendo a trovare l\'attestato di rischio in Banca Dati!</span>',
      '<span class="uxa-text-bold">Ti consigliamo di contattare Apsa</span> all\'indirizzo operativoapsa@agenziapadana.it'
    );
  static readonly LEGGE_BERSANI_ATRC2_NO_MATCH =
    new ERROR_CODE_MESSAGE_MAP(
      "bersani",
      '<span class="uxa-text-bold">Legge Bersani non applicabile per una delle seguenti casistiche</span>: differente codice fiscale o provincia di immatricolazione, attestato di rischio non trovato in banca dati, veicolo di proprietà di una Persona Giuridica.',
      '<span class="uxa-text-bold">Ti consigliamo di contattare Apsa</span> all\'indirizzo operativoapsa@agenziapadana.it');

  static readonly LEGGE_BERSANI_NO_ATRC2 =
    new ERROR_CODE_MESSAGE_MAP(
      "bersani",
      '<span class="uxa-text-bold">Legge Bersani non applicabile per una delle seguenti casistiche</span>: differente codice fiscale o provincia di immatricolazione, attestato di rischio non trovato in banca dati, veicolo di proprietà di una Persona Giuridica.',
      '<span class="uxa-text-bold">Ti consigliamo di contattare Apsa</span> all\'indirizzo operativoapsa@agenziapadana.it'
    );
  static readonly LEGGE_BERSANI_PERSONA_GIURIDICA =
    new ERROR_CODE_MESSAGE_MAP(
      "bersani",
      '<span class="uxa-text-bold">Legge Bersani non applicabile per una delle seguenti casistiche</span>: differente codice fiscale o provincia di immatricolazione, attestato di rischio non trovato in banca dati, veicolo di proprietà di una Persona Giuridica.',
      '<span class="uxa-text-bold">Ti consigliamo di contattare Apsa</span> all\'indirizzo operativoapsa@agenziapadana.it'
    );
  static readonly BONUS_FAMIGLIA_ATRC2_NO_MATCH =
    new ERROR_CODE_MESSAGE_MAP(
      "bonus-famiglia",
      '<span class="uxa-text-bold">Bonus Famiglia non applicabile per una delle seguenti casistiche</span>: differente codice fiscale o provincia di immatricolazione, attestato di rischio non trovato in banca dati, veicolo di proprietà di una Persona Giuridica.',
      '<span class="uxa-text-bold">Ti consigliamo di contattare Apsa</span> all\'indirizzo operativoapsa@agenziapadana.it'
    );
  static readonly BONUS_FAMIGLIA_NO_ATRC2 =
    new ERROR_CODE_MESSAGE_MAP(
      "bonus-famiglia",
      '<span class="uxa-text-bold">Bonus Famiglia non applicabile per una delle seguenti casistiche</span>: differente codice fiscale o provincia di immatricolazione, attestato di rischio non trovato in banca dati, veicolo di proprietà di una Persona Giuridica.',
      '<span class="uxa-text-bold">Ti consigliamo di contattare Apsa</span> all\'indirizzo operativoapsa@agenziapadana.it'
    );
  static readonly BONUS_FAMIGLIA_PERSONA_GIURIDICA =
    new ERROR_CODE_MESSAGE_MAP(
      "bonus-famiglia",
      '<span class="uxa-text-bold">Bonus Famiglia non applicabile per una delle seguenti casistiche</span>: differente codice fiscale o provincia di immatricolazione, attestato di rischio non trovato in banca dati, veicolo di proprietà di una Persona Giuridica.',
      '<span class="uxa-text-bold">Ti consigliamo di contattare Apsa</span> all\'indirizzo operativoapsa@agenziapadana.it'
    );
  static readonly BONUS_FAMIGLIA_NO_VALID =
    new ERROR_CODE_MESSAGE_MAP(
      "bonus-famiglia",
      '<span class="uxa-text-bold">Bonus Famiglia non applicabile per una delle seguenti casistiche</span>: differente codice fiscale o provincia di immatricolazione, attestato di rischio non trovato in banca dati, veicolo di proprietà di una Persona Giuridica.',
      '<span class="uxa-text-bold">Ti consigliamo di contattare Apsa</span> all\'indirizzo operativoapsa@agenziapadana.it'
    );
  static readonly NO_CONV_VALID =
    new ERROR_CODE_MESSAGE_MAP(
      "convenzione",
      '<span class="uxa-text-bold">Non risulta attiva nessuna convenzione!</span>',
      '<span class="uxa-text-bold">Ti consigliamo di contattare Apsa</span> all\'indirizzo operativoapsa@agenziapadana.it'
    );

  static readonly ALREADY_INSURED =
    new ERROR_CODE_MESSAGE_MAP(
      "polizza",
      '<span class="uxa-text-bold">Veicolo con polizza RCA attiva presente in Banca Dati!</span>',
      '<span class="uxa-text-bold">Ti consigliamo di contattare Apsa</span> all\'indirizzo operativoapsa@agenziapadana.it'
    );
  static readonly VEHICLE_DEMOLISHED =
    new ERROR_CODE_MESSAGE_MAP(
      "demolizione",
      '<span class="uxa-text-bold">Il veicolo inserito risulta demolito!</span>',
      '<span class="uxa-text-bold">Ti consigliamo di contattare Apsa</span> all\'indirizzo operativoapsa@agenziapadana.it'
    );

  static readonly SOSTITUZIONE_VEICOLO_NO_SIVI2 =
    new ERROR_CODE_MESSAGE_MAP(
      "generic",
      '<span class="uxa-text-bold">Sostituzione veicolo non applicabile, targa non gestita</span>',
      '<span class="uxa-text-bold">Ti consigliamo di contattare Apsa</span> all\'indirizzo operativoapsa@agenziapadana.it'
    );
  static readonly LEGGE_BERSANI_NO_SIVI2 =
    new ERROR_CODE_MESSAGE_MAP(
      "generic",
      '<span class="uxa-text-bold">Legge Bersani non applicabile, targa non gestita</span>',
      '<span class="uxa-text-bold">Ti consigliamo di contattare Apsa</span> all\'indirizzo operativoapsa@agenziapadana.it'
    );
  static readonly BONUS_FAMIGLIA_NO_SIVI2 =
    new ERROR_CODE_MESSAGE_MAP(
      "generic",
      '<span class="uxa-text-bold">Bonus famiglia non applicabile, targa non gestita</span>',
      '<span class="uxa-text-bold">Ti consigliamo di contattare Apsa</span> all\'indirizzo operativoapsa@agenziapadana.it'
    );

  static readonly NO_SERVER =
    new ERROR_CODE_MESSAGE_MAP(
      'server',
      '<span class="uxa-text-bold">Scusaci, ma il server non sta rispondendo.</span>',
      '<span class="uxa-text-bold">Riprova più tardi!</span>'
    );

  static readonly NO_INTERNET =
    new ERROR_CODE_MESSAGE_MAP(
      'internet',
      '<span class="uxa-text-bold">Prova a dare un\'occhiata alla connessione internet...</span>',
      '<span class="uxa-text-bold">Qualcosa sembra non funzionare!</span>'
    );

  static readonly DOWNLOADER_NO_METADATA =
    new ERROR_CODE_MESSAGE_MAP(
      'find-error',
      '<span class="uxa-text-bold">Il documento firmato non è ancora disponibile.</span>',
      '<span class="uxa-text-bold">Riprova più tardi!</span>'
    );


  static readonly DEFAULT =
    new ERROR_CODE_MESSAGE_MAP(null, "ATTENZIONE", "<span class='uxa-text-bold'>Attenzione si è verificato un errore non previsto</span>");

  static readonly SESSION =
    new ERROR_CODE_MESSAGE_MAP('durata', "<span class='uxa-text-bold'>Ops..</span>", "<span class='uxa-text-bold'>Ci scusiamo ma per garantire la sicurezza dei tuoi dati, la sessione è scaduta</span>",  'Accedi di nuovo', 'SESSION',);


  private constructor(public readonly icon: string, public readonly title: string, public readonly html: string, public readonly cta?: string, public readonly code?: string) {
  }
}

